import {Route, Routes,Navigate } from "react-router-dom";

import ReferralPage from "../../components/referral-page/referral-page.component";

const ReferralRoutes = () => {
    return (
      <Routes>
        <Route index element={<ReferralPage/>} />
        <Route path="referralcode/:code" element={<ReferralPage />} />
        <Route path="*" element={<Navigate to="/referral" />} /> 
      </Routes>
    );
  };
export default ReferralRoutes;