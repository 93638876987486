import { Col, Container, Row } from "react-bootstrap";
import Footer from "../home-page-footer/home-page-footer.component";
import "./membership.style.css";
import BreadcrumbsItemList from "../breadcrumbs/breadcrumbs-item-list.component";
import PageTitle from "../page-title/page-title.component";
import MembershipCardDesign from "../membership-card-design/membership-card-design.component";
import { useEffect, useState } from "react";
import DownloadApp from "../home-page-download/home-page-download.component";
import { useLazyQuery } from "@apollo/client";
import { FETCH_ALL_SUBSCRIPTION_PLAN_DETAILS } from "../../graphQL/subscription/subscription";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import {
  isUserLoggedIn,
  selectCurrentUser,
} from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import GlobalModel from "../global-model/global-model.component";
import LoginModal from "../login/login.component";
import {
  APPLE_STORE_URL,
  GOOGLE_STORE_URL,
  membership_description,
} from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { isAndroid, isDesktop, isIOS } from "react-device-detect";
import DownloadAppModal from "../download-app-modal/download-app-modal.component";

const Membership = () => {
  const [plans, setPlans] = useState([]);
  const [plansDetails, setPlansDetails] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Customer");
  const [isYearly, setIsYearly] = useState(true);
  const isUserAuthenticated = useSelector(isUserLoggedIn);
  const currentUser = useSelector(selectCurrentUser);
  const [showISLogin, setShowIsLogin] = useState(false);
  const [showIsModal, setShowIsModal] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const location = useLocation();
  const { type } = location.state || {};

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (type && type === "user") {
      setSelectedTab("Customer");
      await fetchAllSubscriptionPlanWithDetails({
        variables: {
          type: "Personal",
          duration: "year",
        },
      });
    } else if (type && type === "business") {
      setSelectedTab("Business");
      await fetchAllSubscriptionPlanWithDetails({
        variables: {
          type: "Business",
          duration: "year",
        },
      });
    } else {
      await fetchAllSubscriptionPlanWithDetails({
        variables: {
          type: selectedTab === "Customer" ? "Personal" : "Business",
          duration: isYearly ? "year" : "month",
        },
      });
    }
  };

  const [
    fetchAllSubscriptionPlanWithDetails,
    { loading: loadingSubscriptionPlan },
  ] = useLazyQuery(FETCH_ALL_SUBSCRIPTION_PLAN_DETAILS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchAllSubscriptionPlanWithDetails) {
        const planDetails = data.fetchAllSubscriptionPlanWithDetails;
        if (!isYearly) {
          // console.log("Final data if-------------", planDetails);
          setPlans(planDetails);
          // console.log("Final data if-------------", !isYearly, data);
        } else {
          const details = planDetails.map((plan) => plan.details);
          // console.log("Final data else-------------", details);
          setPlans(planDetails);
          setPlansDetails(details);
        }
      }
    },
  });

  const handleTabChange = async (tab) => {
    setSelectedTab(tab);
    if (tab !== selectedTab) {
      setIsYearly(true);
      await fetchAllSubscriptionPlanWithDetails({
        variables: {
          type: tab === "Customer" ? "Personal" : "Business",
          duration: "year",
        },
      });
    }
  };

  const handleRadioChange = async (radio) => {
    setIsYearly(radio);
    // console.log("radio change-----------", radio);
    await fetchAllSubscriptionPlanWithDetails({
      variables: {
        type: selectedTab === "Customer" ? "Personal" : "Business",
        duration: radio ? "year" : "month",
      },
    });
  };

  const handleButtonClick = () => {
    if (isUserAuthenticated) {
      setShowIsModal(true);
    } else {
      setShowIsLogin(true);
    }
  };

  return (
    <>
      <Container style={{ width: "99%", marginTop: "2%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xl={10} lg={10} md={12} sm={12} xs={12}>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // className="scrollable-product-column"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BreadcrumbsItemList
                  resetFilterSort={() => {}}
                  type={"Membership"}
                />
                <PageTitle title={"Membership"} />
              </div>
            </Col>

            <div className="membership-container">
              <p className="membership-description">
                {isUserAuthenticated
                  ? selectedTab === "Customer"
                    ? membership_description.customer_with_login
                    : membership_description.business_with_login
                  : selectedTab === "Customer"
                  ? membership_description.customer_without_login
                  : membership_description.business_without_login}
              </p>

              {/* Tabs Section */}
              <div className="tabs">
                <div
                  className={`tab ${
                    selectedTab === "Customer" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabChange("Customer");
                  }}
                >
                  Customer
                </div>
                <div
                  className={`tab ${
                    selectedTab === "Business" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabChange("Business");
                  }}
                >
                  Business
                </div>
              </div>

              {/* Toggle Switch */}
              <div className="toggle-switch">
                <span className="toggle-text">Monthly</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isYearly}
                    onChange={() => {
                      handleRadioChange(!isYearly);
                    }}
                  />
                  <span className="toggle-slider"></span>
                </label>
                <span className="toggle-text">Yearly</span>
              </div>
            </div>

            {loadingSubscriptionPlan ? (
              <LoadingSpinner />
            ) : (
              <div className="plans-container mb-4">
                <Row>
                  {plans.map((plan, index) => (
                    <Col
                      key={index}
                      xl={selectedTab === "Customer" ? 3 : 4}
                      lg={selectedTab === "Customer" ? 3 : 4}
                      md={4}
                      sm={12}
                      xs={12}
                      className="d-flex justify-content-center mb-3"
                    >
                      <MembershipCardDesign
                        planName={plan.name}
                        description={plansDetails[index]}
                        currentPrice={plan.fees}
                        originalPrice={plan.monthlyPrice}
                        discount={plan.discount}
                        handleButtonClick={handleButtonClick}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            <DownloadApp
              image={"./images/iPhone 16.svg"}
              title={"Download Free App!"}
              description={"Available for iOS and Adroid."}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
      {showIsModal && (
        <GlobalModel
          show={showIsModal}
          onHide={() => {
            setShowIsModal(false);
          }}
          onRemoveClick={() => {
            setShowIsModal(false);
            if (isDesktop) {
              setIsShowPopup(true);
            } else if (isAndroid) {
              window.location = GOOGLE_STORE_URL;
            } else if (isIOS) {
              window.location = APPLE_STORE_URL;
            }
          }}
          title={
            currentUser?.subscription &&
            currentUser.subscription?.subscriptionType === "Free Plan"
              ? "Complete Your Membership Purchase in the App"
              : "You Already Have a Membership"
          }
          bodyMessage={
            currentUser?.subscription &&
            currentUser.subscription?.subscriptionType === "Free Plan"
              ? "We’re excited to have you join our membership program! For a smooth and secure experience, membership purchases are available exclusively in our app. Open the app to select your plan and enjoy all the benefits!"
              : "You’re already subscribed to a membership plan. To manage or upgrade your membership, please use our app for a seamless experience. Enjoy all the benefits of your current plan, and feel free to explore new options directly in the app!"
          }
          buttonTitle={"Open app"}
          buttonType="normal"
        />
      )}
      <DownloadAppModal
        show={isShowPopup}
        onHide={() => {
          setIsShowPopup(false);
        }}
      />
      <LoginModal
        show={showISLogin}
        onHide={() => {
          setShowIsLogin(false);
        }}
        handleSignInClickLogin={() => {
          setShowIsLogin(true);
        }}
        // title={loginTitle}
      />
    </>
  );
};

export default Membership;
