import { IoPerson } from "react-icons/io5";
import { neturalBlack } from "../../utils/colors";
import { ImBriefcase } from "react-icons/im";

const PlanCard = ({
  planName,
  saving,
  originalPrice,
  discount,
  type,
  handleClick,
}) => {
  return (
    <div
      className="plan-card"
      onClick={() => {
        handleClick(type);
      }}
    >
      {/* Save Banner */}
      <div className="save-banner">
        <span>Save {saving}</span>
      </div>

      {/* Card Content */}
      <div className="plan-content">
        <div className="plan-icon">
          {type === "user" ? (
            <IoPerson color={neturalBlack} size={26} />
          ) : (
            <ImBriefcase color={neturalBlack} size={26} />
          )}
        </div>
        <h2 className="plan-name">{planName}</h2>
        <div className="plan-pricing">
          {planName === "Free Plan" ? (
            <span className="plan-price">FREE</span>
          ) : (
            <>
              <span className="plan-price">{originalPrice}</span>
              <span className="plan-duration">/month</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
