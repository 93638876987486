import React, { useEffect } from "react";
import HomePageBrowseProductsServices from "../home-page-product-and-service/home-page-browse-products-and-services.component";
import ProductServiceViewAllHome from "../home-page-section-3-view-all/product-service-viewall-home.component";
import ImageCarousel from "../image-carousel/image-carousel.component";
import CompanyLogoSlider from "../home-page-logo-slider/home-page-company-logo.component";
import DownloadApp from "../home-page-download/home-page-download.component";
import Footer from "../home-page-footer/home-page-footer.component";
import { useQuery } from "@apollo/client";
import { FETCH_LANDING_PAGE_INFO } from "../../graphQL/landing-page/landing-page";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { isAndroid, isIOS } from "react-device-detect";

const HomePage = () => {
  useEffect(() => {
    // Check if the user has already visited the page
    const hasVisited = sessionStorage.getItem("hasVisited");
    const redirectAndroidURL = process.env.REACT_APP_HOST_URL.includes("my-prod") ? "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1" :`https://play.google.com/apps/test/RQzPbBDBjUQ/ahAO29uNTz0IZAfewr7yzsv6-rU2zOLnJQ7uw_VdKaaEfY_2EFtcm5sy584OVEEtVPNUQe0i2AOSsPISXOnpKLYIoq`;
    const redirectIOSURL = process.env.REACT_APP_HOST_URL.includes("my-prod") ?  "https://apps.apple.com/us/app/my/id1603899001" :  "https://apps.apple.com/us/app/my/id1603899001";
    if (!hasVisited) {
      var deep_link_url = window.location.search;
      var now = new Date().valueOf();

      if (deep_link_url !== "") {
        // Variable will check if the app is installed or not
        var change = false;
      
        setTimeout(() => {
          if (!change) {
            var redirectUrl = "";
            if (isAndroid) {
              redirectUrl = redirectAndroidURL
            } else if (isIOS) {
              redirectUrl = redirectIOSURL;
            }
            window.location = redirectUrl;
          }
        }, 3000);

        window.location = "myapp://" + deep_link_url;

        // Handle event to check if the app is installed or not
        window.onblur = function () {
          change = true;
        };
        window.onfocus = function () {
          change = false;
        };
      } else {
        if (
          (isAndroid || isIOS) &&
          window.confirm("Would you like to visit the App Store?")
        ) {
          setTimeout(function () {
            if (new Date().valueOf() - now > 100) {
              if (isAndroid) {
                window.location.href = redirectAndroidURL
              } else if (isIOS) {
                window.location.href = redirectIOSURL;
              }
            }
          }, 500);
        }
      }
      // Mark the user as having visited the page
      sessionStorage.setItem("hasVisited", true);
    }
  }, []);

  const { loading, error, data } = useQuery(FETCH_LANDING_PAGE_INFO);
  if (loading) return <LoadingSpinner overlay />;
  if (error) return <p>Error: {error.message}</p>;
  // if (data) {
  //   console.log("FETCH LANDING PAGE INFO", data.fetchingLandingPageInfo)
  // }

  return (
    <>
      <ImageCarousel imagesList={data.fetchingLandingPageInfo.carouselImages} />
      <HomePageBrowseProductsServices />
      <ProductServiceViewAllHome
        products={data.fetchingLandingPageInfo.products}
        title={"Products"}
      />
      <CompanyLogoSlider logo={data.fetchingLandingPageInfo.trustedPatners} />
      <DownloadApp
        image={"./images/iPhone 16.svg"}
        title={"Download Free App!"}
        description={"Available for iOS and Adroid."}
      />
      <Footer />
    </>
  );
};

export default HomePage;
