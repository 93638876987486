import _ from "lodash";

export const APPLE_STORE_URL = "https://apps.apple.com/us/app/my/id1603899001";
export const GOOGLE_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1";
export const BUSINESS_SUBSCRIPTION = {
  FREE_PLAN: "Free Plan",
  PRO_PLAN: "Pro Plan",
  PREMIUM_PLAN: "Premium Plan",
};
/**
 * Business Verification status
 * 1)Pending:- User Just created Business Account and needs to do verification
 * 2)Processing:- User has submitted verification form
 * 3)Approved :- Business Account is verified
 * 4)Rejected :- Business Verification is failed due to wrong info
 * 5)Reverifying:- Business was approved and then business update some information and send it for verifiying
 **/
export const BUSINESS_VERIFICATION_STATUS_TYPES = {
  PENDING: "Pending",
  PROCESSING: "Processing",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  REVERIFYING: "Reverifying",
};

export const returnPaginationRange = (totalPage, page, siblings, limit) => {
  let totalPageNoInArray = 5 + siblings;
  if (totalPageNoInArray >= totalPage) {
    return _.range(1, totalPage + 1);
  }
  let leftSiblingIndex = Math.max(page - siblings, 1);
  let rightSiblingIndex = Math.min(page + siblings, totalPage);

  let showLeftDots = leftSiblingIndex > 2;
  let showRightDots = rightSiblingIndex < totalPage - 2;

  if (!showLeftDots && showRightDots) {
    let leftItemCount = 3 + 2 * siblings;
    let leftRange = _.range(1, leftItemCount + 1);

    return [...leftRange, "...", totalPage];
  } else if (showLeftDots && !showRightDots) {
    let rightItemCount = 3 + 2 * siblings;
    let rightRange = _.range(totalPage - rightItemCount + 1, totalPage + 1);

    return [1, "...", ...rightRange];
  } else {
    let middleRange = _.range(leftSiblingIndex, rightSiblingIndex + 1);

    return [1, "...", ...middleRange, "...", totalPage];
  }
};

/**
 * Item types indicating the type of item.
 *
 * @constant
 * @type {Object}
 * @property {string} PRODUCT - Indicates that the item is a product.
 * @property {string} SERVICE - Indicates that the item is a service.
 */
export const ITEM_TYPE = {
  PRODUCT: "Product",
  SERVICE: "Service",
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const tutorial_data = [
  {
    title: "The Destination for Shopping and Connections",
    body: [
      "Welcome to “My...” !, your go-to hub for convenience and connection. With everything you need in one place, it’s more than just shopping—it’s about connection, discovery, and savings.",
      "Explore, engage, and uncover new favourites while staying connected to the people and things that matter most, both in your community and beyond.",
    ],
    videoURL: "https://www.youtube.com/watch?v=V4Rgay3O4fQ",
  },
  {
    title: "Explore and Navigate “My...” with Ease",
    body: [
      "Discover how to make the most of “My...” with our step-by-step video tutorial. Learn how to purchase products and services, follow interests that inspire you, and even create your own to share with the community.",
      "This guide walks you through the platform's features, ensuring you can explore, connect, and customize your experience effortlessly. Watch now and get started with ease!",
    ],
    videoURL: "https://www.youtube.com/watch?v=HMM3wcNJxyY",
  },
  {
    title: "Getting Started & Growing Your Business",
    body: [
      "This video tutorial is your ultimate guide to getting started as a business on “My...”. Discover how to set up and verify your business account, and seamlessly create products and services using both the app and dashboard.",
      "From account setup to showcasing your offerings, this step-by-step guide empowers your business to thrive and reach more customers effortlessly.",
    ],
    videoURL: "https://www.youtube.com/watch?v=rc6ZEVuqy5Q",
  },
];

export const membership_description = {
  customer_without_login:
    "Create an account or log in to explore our membership plans designed for customers. Unlock exclusive deals, perks, and a seamless shopping experience by joining today!",
  customer_with_login:
    "Get a new membership or manage your current plan by visiting your account in the app for full control to enjoy even more benefits tailored to your shopping needs. ",
  business_without_login:
    "Create a business account on our app or log in if you’re already a business to access our membership plans for businesses. Enjoy tools and features designed to help you reach your goals and boost your sales.",
  business_with_login:
    "Manage your current plan and upgrade your membership directly in our app to unlock advanced features and maximize your business potential.",
};

export const tutorial_user_plan = [
  {
    planName: "Free Plan",
    saving: "$85.65",
    originalPrice: "$00",
    discount: "$5",
  },
  {
    planName: "Silver Plan",
    saving: "$118.51",
    originalPrice: "$9.99",
    discount: "$7",
  },
  {
    planName: "Gold Plan",
    saving: "$253.95",
    originalPrice: "$14.99",
    discount: "$15",
  },
  {
    planName: "Platinum Plan",
    saving: "$338.60",
    originalPrice: "$69.99",
    discount: "$20",
  },
];

export const tutorial_business_plan = [
  {
    planName: "Free Plan",
    saving: "$1535.65",
    originalPrice: "$00",
    discount: "$5",
  },
  {
    planName: "Pro Plan",
    saving: "$2118.51",
    originalPrice: "$15.99",
    discount: "$5",
  },
  {
    planName: "Premium Plan",
    saving: "$4603.9",
    originalPrice: "$69.99",
    discount: "$15",
  },
];

export const referral_steps = [
  {
    id: 1,
    title: "Share Your Referral Link",
    description:
      "Send your unique link to friends from the referral tab—both of you get $20 when they join! Earn up to $100 per account.",
    image: "Referral_section1",
  },
  {
    id: 2,
    title: "Collect Your Cash Credit",
    description:
      "Once they sign up, you’ll both receive a confirmation and $20 in credit.",
    image: "Referral_section2",
  },
  {
    id: 3,
    title: "Redeem Your Credits",
    description:
      "Use your earned cash credit on any transaction in My... App. Start sharing and earning today!",
    image: "Referral_section3",
  },
];

export const faqData = [
  {
    question: "Where do I find my referral link?",
    answer:
      'You can find your unique referral link in the app under the "Refer & Earn" section. Simply copy the link or share it directly through social media, text, or email.',
  },
  {
    question: "When will I get my $20?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
  },
  {
    question: "Does my friend also get $20?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut comm.",
  },
  {
    question: "Can I refer more than one friend?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut comm.",
  },
  {
    question: "Is there an expiration date for my rewards?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut comm.",
  },
];
