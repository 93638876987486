import { IoCheckmarkCircle, IoPerson } from "react-icons/io5";
import { neturalBlack } from "../../utils/colors";
import "./membership-card-design.style.css";

const MembershipCardDesign = ({
  planName,
  description,
  currentPrice,
  originalPrice,
  discount,
  handleButtonClick,
}) => {
  return (
    <div class="pricing-card">
      <div className="plan-content">
        <div class="plan-header mb-2">
          <IoPerson color={neturalBlack} size={26} />
        </div>
        <h2 className="plan-name mb-2 mt-2" >{planName}</h2>
      </div>
      <div className="plan-pricing mt-2" style={{ marginBottom: '16px' }} >
        {currentPrice === "0" ? (
          <span className="plan-price">FREE</span>
        ) : (
          <>
            <span className="plan-price">${currentPrice}</span>
            {originalPrice && originalPrice !== "0" ? (
              <span className="original-price">${originalPrice}</span>
            ) : null}
            <span className="plan-duration">/month</span>
          </>
        )}
      </div>
      <button class="plan-button" onClick={handleButtonClick}>
        Buy
      </button>
      <ul className="plan-features">
        {description.map((text) => {
          return (
            <li>
              <IoCheckmarkCircle className="icon-check" />
              <span>{text}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MembershipCardDesign;
