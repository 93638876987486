import { Card, Col, Container, Image, Row } from "react-bootstrap";
import BreadcrumbsItemList from "../breadcrumbs/breadcrumbs-item-list.component";
import PageTitle from "../page-title/page-title.component";
import SectionBody from "../section-body/section-body.component";
import SectionTitle from "../section-title/section-title.component";
import {
  APPLE_STORE_URL,
  faqData,
  GOOGLE_STORE_URL,
  referral_steps,
} from "../../utils/constants";
import Footer from "../home-page-footer/home-page-footer.component";
import Collapsible from "react-collapsible";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import CustomLabel from "../shared/label/custom-label.component";
import DownloadApp from "../home-page-download/home-page-download.component";
import { isAndroid, isIOS } from "react-device-detect";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ReferralPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { code } = useParams(); // Extract the referral code from the path

  useEffect(() => {
    if (code) {
      // If a referral code is found, try to open the app with deep link
      const deepLink = `myapp://signup?referralcode=${code}`;
      const redirectAndroidURL = process.env.REACT_APP_HOST_URL.includes("my-prod") ? "https://play.google.com/store/apps/details?id=com.stefan23.Ghabryal.v1" : process.env.REACT_APP_HOST_URL.includes("my-dev") ? `https://play.google.com/apps/test/RQzPbBDBjUQ/ahAO29uNSU54q9s1YcqAdGDJtLStgpPgckjD1jtmpdbRctU3wXp31B57IjogR59HxmLGwz40_ei-aTxNcWnf7Z0Lin`:`https://play.google.com/apps/test/RQzPbBDBjUQ/ahAO29uNSuy7r82F4MNCD9TPgNmHag7zsY0ZP9WHEeR8BXmn9Qnp23I7RQBJGQD2ExfW9mK7-EZ_n2t1p1KOf1L6n8`;
      const redirectIOSURL = process.env.REACT_APP_HOST_URL.includes("my-prod") ?  "https://apps.apple.com/us/app/my/id1603899001" :  "https://apps.apple.com/us/app/my/id1603899001";

      // Try to open the app with deep link
      const redirectToApp = () => {
        window.location.href = deepLink;

        //   // If app is not installed, redirect to app store after a delay
        setTimeout(() => {
          var redirectUrl = "";
          if (isAndroid) {
            redirectUrl = redirectAndroidURL;
          } else if (isIOS) {
            redirectUrl = redirectIOSURL;
          }
          window.location = redirectUrl;
        }, 3000);
      };

      // Trigger redirection to the app or store
      redirectToApp();
    }
  }, [location, navigate, code]);
  return (
    <>
      <Container style={{ width: "99%", marginTop: "2%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xl={10} lg={10} md={12} sm={12} xs={12}>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // className="scrollable-product-column"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BreadcrumbsItemList
                  resetFilterSort={() => {}}
                  type={"Referral"}
                />
                <PageTitle title={"Referral"} />
              </div>
            </Col>

            <Row>
              <Col
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="text-column"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <SectionTitle title="Refer a Friend, Get $20 Each! 🎉" />
                <SectionBody className="mt-3">
                  Share your referral link from our app and get rewarded when
                  your friend joins the app. Your friend gets $20 too!
                </SectionBody>
                <div className="mt-3">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "3%",
                    }}
                  >
                    <a
                      href={GOOGLE_STORE_URL}
                      style={{ marginRight: "3%" }}
                      target="_blank"
                    >
                      <img src="./images/Badge.png" alt="" />
                    </a>
                    <a href={APPLE_STORE_URL} target="_blank">
                      <img src="./images/Frame 11.png" alt="" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="image-column"
              >
                <Image
                  src={
                    process.env.PUBLIC_URL + "/images/Referral_Illustration.png"
                  }
                  alt="Logo"
                  // className="logo-image"
                  style={{ objectFit: "contain", width: "90%" }}
                />
              </Col>
            </Row>

            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10%",
              }}
            >
              <Col
                xl={8}
                lg={8}
                md={10}
                sm={12}
                xs={12}
                className="text-column"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SectionTitle title="How it works" />
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <SectionBody className="mt-3">
                    Share your referral link and get $20 credit when your friends
                    sign up. They also get $20 credit to use on their first
                    order!
                  </SectionBody>
                </Col>
                {referral_steps.map((steps, index) => {
                  return (
                    <div className="mt-3" key={index}>
                      <Card style={{ borderRadius: "24px" }} className="mt-4">
                        <Row
                          className="d-flex flex-md-row flex-column-reverse"
                          style={{ alignItems: "center" }}
                        >
                          <Col
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="text-column"
                            style={{ marginBottom: 0 }}
                          >
                            <Image
                              src={
                                process.env.PUBLIC_URL +
                                `/images/${steps.image}.png`
                              }
                              alt="Logo"
                              className="referral-section-image"
                              style={{
                                borderTopLeftRadius: 24,
                                borderBottomLeftRadius: 24,
                                width: "100%",
                              }}
                            />
                          </Col>
                          <Col
                            xl={6}
                            lg={6}
                            md={6}
                            sm={11}
                            xs={11}
                            className="text-column mt-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                              paddingRight: "2%",
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: "black",
                                color: "white",
                                borderRadius: "50%",
                                width: "32px",
                                height: "32px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "16px",
                                minWidth: "32px",
                              }}
                              className="mb-3"
                            >
                              {steps.id}
                            </span>

                            <SectionTitle title={steps.title} />
                            <SectionBody className="mt-2">
                              {steps.description}
                            </SectionBody>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  );
                })}
              </Col>
            </Row>

            {/* <Row style={{ marginTop: "10%" }}> */}
            {/* Left Section */}
            {/* <Col
                xl={5}
                lg={5}
                md={12}
                sm={12}
                xs={12}
                className=" mb-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SectionTitle title="FAQs" />
                <SectionBody className="mt-3">
                  Got Questions? We’ve Got Answers
                </SectionBody>
              </Col> */}

            {/* Right Section */}
            {/* <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                {faqData.map((faq, index) => (
                  <div
                    key={index}
                    className={`mb-4 pb-4 border-bottom ${
                      index === 0 ? "border-top d-lg-none" : ""
                    }`}
                  >
                    <Collapsible
                      trigger={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomLabel
                            style={{
                              width: "98%",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            {faq.question}
                          </CustomLabel>
                          <IoChevronDownOutline style={{ marginLeft: "1%" }} />
                        </div>
                      }
                      transitionTime={200}
                      triggerWhenOpen={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomLabel
                            style={{
                              width: "98%",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            {faq.question}
                          </CustomLabel>
                          <IoChevronUpOutline />
                        </div>
                      }
                      open
                    >
                      <div
                        style={{
                          marginTop: "1%",
                        }}
                      >
                        <CustomLabel
                          style={{
                            textAlign: "justify",
                            textSize: 16,
                            fontWeight: "medium",
                          }}
                        >
                          {faq.answer}
                        </CustomLabel>
                      </div>
                    </Collapsible>
                  </div>
                ))}
              </Col> */}
            {/* </Row> */}

            <Row style={{ marginTop: "10%" }}>
              <DownloadApp
                image={
                  process.env.PUBLIC_URL + `/images/referral_phone_mockup.png`
                }
                title={"Get Rewarded on the Go!"}
                description={
                  "Share your unique link directly from the app and earn referral credits when your friends sign up. The more you share, the more you earn!"
                }
              />
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ReferralPage;
