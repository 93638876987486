import { MdLockOutline } from "react-icons/md";
import {
  netural400,
  neturalBlack,
  neutral200,
  primaryOrange,
} from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const ReferralCheckoutBanner = ({
  referralPromotion,
  isValidCoupon,
  isDiscountApplied,
  checkountInfo,
  applyReferral,
  setApplyReferral,
  calculateReferralPoints,
}) => {
  return referralPromotion.showPromotion &&
    !isValidCoupon &&
    !isDiscountApplied ? (
    <>
      <div style={{ marginTop: "4%" }}>
        <CustomLabel style={{ fontSize: "18px", fontWeight: "bold" }}>
          Referral credit
        </CustomLabel>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px 16px",
            borderRadius: "12px",
            border: "1px solid #dadada",
            width: "100%",
            marginTop: "16px",
          }}
        >
          {/* Left Side: Lock Icon and Text */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {checkountInfo.subTotal >=
            referralPromotion?.referralPromotion?.minimumOrderValue ? (
              <img
                src="../images/offer_icon.PNG"
                alt={"Offer"}
                style={{ width: "24px", height: "24px" }}
              />
            ) : (
              <MdLockOutline size={24} color="#000" />
            )}
            <div>
              {checkountInfo.subTotal >=
              referralPromotion?.referralPromotion?.minimumOrderValue ? (
                <>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: neturalBlack,
                    }}
                  >
                    {!applyReferral
                      ? `Save $${referralPromotion?.referralPromotion?.rewardAmount}`
                      : `You saved $${referralPromotion?.referralPromotion?.rewardAmount}`}
                  </div>
                  <div style={{ fontSize: "14px", color: netural400 }}>
                    {!applyReferral
                      ? "Apply referral credit"
                      : "from referral credits"}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: neturalBlack,
                    }}
                  >
                    {referralPromotion?.referralPromotion?.title}
                  </div>
                  <div style={{ fontSize: "14px", color: netural400 }}>
                    {`Add $${
                      referralPromotion?.referralPromotion?.minimumOrderValue -
                      checkountInfo.subTotal
                    } more to apply referral credit`}
                  </div>
                </>
              )}
            </div>
          </div>

          {checkountInfo.subTotal >=
          referralPromotion?.referralPromotion?.minimumOrderValue ? (
            <button
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: primaryOrange,
                padding: "1%",
              }}
              onClick={() => {
                setApplyReferral(!applyReferral);
                calculateReferralPoints(!applyReferral);
              }}
            >
              {!applyReferral ? "Apply" : "Remove"}
            </button>
          ) : (
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: neutral200,
                padding: "1%",
              }}
            >
              Apply
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
};

export default ReferralCheckoutBanner;
