import { Col, Container, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import Footer from "../home-page-footer/home-page-footer.component";
import LoginModal from "../login/login.component";
import ReviewModal from "../review-modal/review-modal.component";
import { netural400, netural50, neturalBlack } from "../../utils/colors";
import ReviewList from "../product-detail-review-list/product-detail-review-list.component";
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoLocationOutline,
} from "react-icons/io5";
import { Rating } from "react-simple-star-rating";
import CustomLabel from "../shared/label/custom-label.component";
import Collapsible from "react-collapsible";
import CustomButton from "../button/button.component";
import ProductDetailPrice from "../product-price/product-detail-price.component";
import { BsChevronRight, BsDot } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { useDispatch, useSelector } from "react-redux";
import {
  isUserLoggedIn,
  selectCurrentUser,
} from "../../store/user/user.selector";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FaRegClock } from "react-icons/fa6";
import ServiceCalendarModal from "../calendar-modal/service-calendar-modal.compinent";
import ServiceSpotsModal from "../service-spots-modal/service-spots-modal.component";
import {
  BUSINESS_SUBSCRIPTION,
  BUSINESS_VERIFICATION_STATUS_TYPES,
  ITEM_TYPE,
  isEmptyObject,
} from "../../utils/constants";
import { toast } from "react-toastify";
import {
  FETCH_SERVICE_DETAILS_BY_ID,
  VERIFY_AVAILABLE_SLOT_SPOTS,
} from "../../graphQL/services/services";
import { setUserServiceCart } from "../../store/cart/cart.action";
import GlobalModel from "../global-model/global-model.component";

const ServiceDetail = () => {
  const params = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const { serviceId } = params;
  const isUserAuthenticated = useSelector(isUserLoggedIn);
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [isShowPerson, setIsShowPerson] = useState(false);
  const [isBusinessAllowToSell, setIsBusinessAllowToSell] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [service, setService] = useState({});
  const [serviceMedia, setServiceMedia] = useState([]);
  const [serviceReview, setServiceReview] = useState([]);
  const [loginTitle, setLoginTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [noOfPerson, setNoOfPerson] = useState([]);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState({
    title: "",
    message: "",
    buttonTitle: "",
  });

  useEffect(() => {
    fetchServiceDetailsById({
      variables: {
        itemId: atob(serviceId),
        type: ITEM_TYPE.SERVICE,
      },
    });
  }, []);

  const [fetchServiceDetailsById, { loading: serviceLoading }] = useLazyQuery(
    FETCH_SERVICE_DETAILS_BY_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchItemDetailsById) {
          let serviceInfo = data.fetchItemDetailsById;
          setService(serviceInfo);
          setServiceMedia(serviceInfo.medias);
          setServiceReview(serviceInfo.reviews);
        }
      },
    }
  );
  useEffect(() => {
    if (!isEmptyObject(service) && !serviceLoading) {
      if (
        (service.subscriptionName === BUSINESS_SUBSCRIPTION.PRO_PLAN ||
          service.subscriptionName === BUSINESS_SUBSCRIPTION.PREMIUM_PLAN) &&
        (service.businessVerificationStatus ===
          BUSINESS_VERIFICATION_STATUS_TYPES.APPROVED ||
          service.businessVerificationStatus ===
            BUSINESS_VERIFICATION_STATUS_TYPES.REVERIFYING)
      ) {
        setIsBusinessAllowToSell(true);
      } else {
        setIsBusinessAllowToSell(false);
      }
    } else {
      setIsBusinessAllowToSell(false);
    }
  }, [service, serviceLoading]);

  const [verifyAvailableSlotSpots, { loading: verifySlotSpotsLoading }] =
    useMutation(VERIFY_AVAILABLE_SLOT_SPOTS);

  const checkAvaliableSlots = async () => {
    if (service.userId === currentUser._id) {
      toast.error("Sorry, You cant purchase your own Service.", {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    // console.log("Final value--------------", noOfPerson, service);
    let result = await verifyAvailableSlotSpots({
      variables: {
        serviceId: atob(serviceId),
        selectedDate: selectedDate,
        selectedStartTime: selectedTimeSlot.startTime,
        selectedEndTime: selectedTimeSlot.endTime,
        spots: noOfPerson.length,
      },
    });
    if (result.data.verifyAvailableSlotSpots.success === true) {
      let bookingData = {
        serviceType:
          service.serviceType === "Online/In person"
            ? serviceType
            : service.serviceType,
        serviceSatrtTime: selectedTimeSlot.startTime,
        serviceEndTime: selectedTimeSlot.endTime,
        bookingDate: selectedDate,
        bookingClients: noOfPerson,
        noOfSpots: noOfPerson.length,
      };
      let finalBookingData = {
        ...service,
        bookingData: bookingData,
      };
      dispatch(setUserServiceCart(finalBookingData));
      navigation("/buy?type=service", { state: finalBookingData.businessId });
      // alert("Message: Successfully done!");
    } else {
      // setAvailabeSlots(result.data.verifyAvailableSlotSpots.remainingCapacity);
      const itemAvaibility =
        result.data.verifyAvailableSlotSpots.itemAvaibility;
      setIsErrorVisible(true);
      setIsErrorMessage({
        title: itemAvaibility[0].errorTitle,
        message: itemAvaibility[0].message,
        buttonTitle: itemAvaibility[0].buttonTitle,
      });
    }
  };

  return (
    <>
      <Container style={{ width: "99%", marginTop: "3%" }}>
        {serviceLoading ? (
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingSpinner overlay />
          </div>
        ) : (
          <>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col lg={10} md={10} sm={12} xs={12}>
                <Row>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "3%",
                      }}
                    >
                      <Link to={"/"}>
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: netural400,
                          }}
                        >
                          Home
                        </CustomLabel>{" "}
                      </Link>
                      <CustomLabel
                        style={{ marginLeft: "1%", marginRight: "1%" }}
                      >
                        /
                      </CustomLabel>
                      <Link to={"/services"}>
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: netural400,
                          }}
                        >
                          Services
                        </CustomLabel>{" "}
                      </Link>
                      <CustomLabel
                        style={{ marginLeft: "1%", marginRight: "1%" }}
                      >
                        /
                      </CustomLabel>
                      <Link>
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {service.title}
                        </CustomLabel>{" "}
                      </Link>
                    </div>
                    <Carousel
                      emulateTouch
                      autoPlay
                      // infiniteLoop={false}
                      showArrows={false}
                      showIndicators={false}
                      showStatus={false}
                      dynamicHeight={false}
                    >
                      {serviceMedia.map((data, index) => {
                        return (
                          <div key={index}>
                            <img
                              alt={service.title}
                              // src={"../../images/Product-Image.png"}
                              src={`${process.env.REACT_APP_HOST_URL}${data.uri}`}
                              className="product-detail-image"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </Col>
                  <Col
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="offset-sm-1"
                  >
                    <div>
                      <CustomLabel
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: netural400,
                          width: "100%",
                        }}
                      >
                        {service.providerName}
                      </CustomLabel>
                      <CustomLabel
                        style={{
                          fontSize: "32px",
                          fontWeight: "bold",
                          color: neturalBlack,
                        }}
                      >
                        {service.title}
                      </CustomLabel>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Rating
                          allowFraction
                          size={20}
                          initialValue={service.totalRating}
                          readonly
                          fillColor={neturalBlack}
                        />
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            color: neturalBlack,
                            marginLeft: "1%",
                          }}
                        >
                          {" "}
                          ({service.totalRating} stars){" "}
                        </CustomLabel>
                        <BsDot size={18} />
                        <CustomLabel
                          style={{
                            fontSize: "14px",
                            color: neturalBlack,
                            marginLeft: "1%",
                          }}
                        >
                          {" "}
                          {service.totalReview}
                        </CustomLabel>
                      </div>
                      <div style={{ marginTop: "1%" }}>
                        <ProductDetailPrice
                          product={service}
                          priceStyle={{
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                          finalTotalStyle={{
                            fontSize: "24px",
                            fontWeight: "bold",
                          }}
                          type={"service"}
                        />
                        <CustomLabel></CustomLabel>
                      </div>
                      {service.serviceType === "Online/In person" &&
                        isBusinessAllowToSell && (
                          <div
                            style={{
                              marginTop: "5%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <CustomLabel
                                style={{
                                  marginRight: "2%",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                1.
                              </CustomLabel>
                              <CustomLabel
                                style={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                Choose service method
                              </CustomLabel>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "3%",
                                border: "1px solid #dadada",
                                borderRadius: "12px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "3%",
                                  }}
                                  onClick={() => {
                                    setServiceType("Online");
                                  }}
                                >
                                  <input
                                    id={`radio-online`}
                                    type="radio"
                                    name="group1"
                                    value={"Online"}
                                    className="custom-radio"
                                    checked={serviceType === "Online"} // Check the radio button if lastSelected is true
                                    onChange={() => setServiceType("Online")} // Update the selected address
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "1%",
                                    }}
                                  >
                                    <label
                                      htmlFor={`radio-online`}
                                      style={{
                                        marginBottom: "1%",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "93%",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: neturalBlack,
                                      }}
                                    >
                                      Online
                                    </label>
                                    <label
                                      htmlFor={`radio-online`}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        fontSize: "14px",
                                        color: netural400,
                                      }}
                                    >
                                      You will receive a link to join the
                                      meeting
                                    </label>
                                  </div>
                                </div>

                                <div
                                  style={{ borderTop: "1px solid #dadada" }}
                                ></div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "3%",
                                  }}
                                  onClick={() => {
                                    setServiceType("In person");
                                  }}
                                >
                                  <input
                                    id={`radio-inperson`}
                                    type="radio"
                                    name="group1"
                                    value={"In person"}
                                    className="custom-radio"
                                    checked={serviceType === "In person"} // Check the radio button if lastSelected is true
                                    onChange={() => setServiceType("In person")} // Update the selected address
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "1%",
                                    }}
                                  >
                                    <label
                                      htmlFor={`radio-inperson`}
                                      style={{
                                        marginBottom: "1%",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "93%",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: neturalBlack,
                                      }}
                                    >
                                      In person
                                    </label>
                                    <label
                                      htmlFor={`radio-inperson`}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        fontSize: "14px",
                                        color: netural400,
                                      }}
                                    >
                                      You will meet the service provider at
                                      their address
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {isBusinessAllowToSell ? (
                        <>
                          <div
                            style={{
                              marginTop: "5%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <CustomLabel
                                style={{
                                  marginRight: "2%",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {service.serviceType === "Online/In person"
                                  ? "2."
                                  : "1."}
                              </CustomLabel>
                              <CustomLabel
                                style={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                Choose available time
                              </CustomLabel>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "3%",
                                border: "1px solid #dadada",
                                borderRadius: "12px",
                                padding: "3%",
                              }}
                              onClick={() => {
                                if (!isUserAuthenticated) {
                                  setLoginTitle(
                                    "Please log in using phone number registered to check slots."
                                  );
                                  setIsShowLogin(true);
                                } else {
                                  setIsShowCalendar(true);
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <CustomLabel
                                  style={{
                                    marginRight: "2%",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    marginBottom: "1%",
                                  }}
                                >
                                  Date
                                </CustomLabel>
                                <CustomLabel style={{ fontSize: "16px" }}>
                                  {selectedDate !== ""
                                    ? selectedDate + " - "
                                    : ""}
                                  {Object.keys(selectedTimeSlot).length > 0
                                    ? selectedTimeSlot.startTime
                                    : ""}{" "}
                                </CustomLabel>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <BsChevronRight />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: "5%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            onClick={() => {
                              if (!isUserAuthenticated) {
                                setLoginTitle(
                                  "Please log in using phone number registered to check slots."
                                );
                                setIsShowLogin(true);
                              } else {
                                if (selectedDate !== "") {
                                  setIsShowPerson(true);
                                } else {
                                  toast.error("Please select date and slot!", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  });
                                }
                              }
                            }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <CustomLabel
                                style={{
                                  marginRight: "2%",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {service.serviceType === "Online/In person"
                                  ? "3."
                                  : "2."}
                              </CustomLabel>
                              <CustomLabel
                                style={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                Number of people
                              </CustomLabel>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "3%",
                                border: "1px solid #dadada",
                                borderRadius: "12px",
                                padding: "3%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <CustomLabel
                                  style={{
                                    marginRight: "2%",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    marginBottom: "1%",
                                  }}
                                >
                                  Spots
                                </CustomLabel>
                                <CustomLabel style={{ fontSize: "16px" }}>
                                  {noOfPerson.length > 0
                                    ? noOfPerson.length + " "
                                    : "No "}
                                  spots taken
                                </CustomLabel>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <BsChevronRight />
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: "8%" }}>
                            <CustomButton
                              title={"Continue"}
                              isValid={
                                isUserAuthenticated &&
                                selectedDate !== "" &&
                                selectedTimeSlot !== "" &&
                                noOfPerson.length > 0 &&
                                (serviceType !== "" ||
                                  service.serviceType !== "Online/In person")
                                  ? true
                                  : false
                              }
                              buttonType={"validation"}
                              handleButtonClick={() => {
                                checkAvaliableSlots();
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={5}
                md={5}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "3%",
                }}
              >
                <div
                  style={{
                    borderTop: "1px solid #dadada",
                    paddingTop: "3%",
                    paddingBottom: "3%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FaRegClock
                      style={{ marginRight: "2%", fontWeight: "600" }}
                    />
                    <CustomLabel
                      style={{
                        marginRight: "1%",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: neturalBlack,
                      }}
                    >
                      {service?.duration?.hours}{" "}
                      {service?.duration?.hours > 1 ? " hours" : " hour"}
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        marginRight: "1%",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: neturalBlack,
                      }}
                    >
                      {service?.duration?.minutes} {" minutes"}
                    </CustomLabel>
                    <BsDot style={{ marginRight: "1%" }} />
                    <CustomLabel
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: neturalBlack,
                      }}
                    >
                      {service.serviceType}
                    </CustomLabel>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #dadada",
                    paddingTop: "3%",
                    paddingBottom: "3%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <IoLocationOutline
                      style={{ marginRight: "2%", fontWeight: "600" }}
                      size={22}
                    />
                    <CustomLabel
                      style={{
                        marginRight: "1%",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: neturalBlack,
                      }}
                    >
                      {service.serviceLocation}
                    </CustomLabel>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "3%",
                    borderTop: "1px solid #dadada",
                    borderBottom: "1px solid #dadada",
                    paddingTop: "3%",
                    paddingBottom: "3%",
                  }}
                >
                  <Collapsible
                    triggerStyle={{
                      fontSize: "16px",
                      fontWeight: "500",
                      width: "100%",
                      display: "flex",
                    }}
                    trigger={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomLabel
                          style={{
                            width: "98%",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Service description
                        </CustomLabel>
                        <IoChevronDownOutline />
                      </div>
                    }
                    triggerWhenOpen={
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomLabel
                          style={{
                            width: "98%",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Service description
                        </CustomLabel>
                        <IoChevronUpOutline />
                      </div>
                    }
                  >
                    <div
                      style={{
                        marginTop: "3%",
                      }}
                    >
                      <CustomLabel style={{ textAlign: "justify" }}>
                        {service.description}
                      </CustomLabel>
                    </div>
                  </Collapsible>
                </div>
                {service.totalReview !== "0" && (
                  <div
                    style={{
                      marginTop: "3%",
                      borderBottom: "1px solid #dadada",
                      paddingTop: "3%",
                      paddingBottom: "3%",
                    }}
                  >
                    <Collapsible
                      triggerStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "100%",
                        display: "flex",
                      }}
                      trigger={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomLabel
                            style={{
                              width: "98%",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Reviews ({service.totalReview})
                          </CustomLabel>
                          <Rating
                            allowFraction
                            size={20}
                            initialValue={service.totalRating}
                            readonly
                            fillColor={neturalBlack}
                          />
                          <IoChevronDownOutline style={{ marginLeft: "1%" }} />
                        </div>
                      }
                      triggerWhenOpen={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomLabel
                            style={{
                              width: "98%",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Reviews ({service.totalReview})
                          </CustomLabel>
                          <Rating
                            allowFraction
                            size={20}
                            initialValue={service.totalRating}
                            readonly
                            fillColor={neturalBlack}
                          />
                          <IoChevronUpOutline style={{ marginLeft: "1%" }} />
                        </div>
                      }
                    >
                      <ReviewList reviews={serviceReview} />
                      {parseInt(service?.totalReview) ===
                      serviceReview?.length ? (
                        <div
                          style={{
                            marginTop: "7%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: neturalBlack,
                              marginLeft: "10%",
                              marginRight: "10%",
                              width: "100%",
                              backgroundColor: netural50,
                              borderRadius: "12px",
                            }}
                            onClick={() => {
                              // setShowReviewModal(true);
                              if (!isUserAuthenticated) {
                                setLoginTitle(
                                  "Please log in using phone number registered to read more reviews."
                                );
                                setIsShowLogin(true);
                              } else {
                                setShowReviewModal(true);
                              }
                            }}
                          >
                            Read more reviews
                          </button>
                        </div>
                      ) : null}
                    </Collapsible>
                  </div>
                )}
              </Col>
              <Col lg={5} md={5} sm={12} xs={12}></Col>
            </Row>
            <LoginModal
              show={isShowLogin}
              onHide={() => {
                setIsShowLogin(false);
              }}
              handleSignInClickLogin={() => {
                setIsShowLogin(true);
              }}
              title={loginTitle}
            />
            {showReviewModal && (
              <ReviewModal
                show={showReviewModal}
                onHide={() => {
                  setShowReviewModal(false);
                }}
                reviewList={serviceReview}
                item_id={atob(serviceId)}
                totalReview={service.totalReview}
              />
            )}
          </>
        )}
      </Container>
      {isShowCalendar && (
        <ServiceCalendarModal
          show={isShowCalendar}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          onHide={() => {
            setIsShowCalendar(false);
          }}
          handleClick={(selectedDate, isSelectedSlots) => {
            setSelectedDate(selectedDate);
            setSelectedTimeSlot(isSelectedSlots);
          }}
          serviceId={atob(serviceId)}
        />
      )}
      {isShowPerson && (
        <ServiceSpotsModal
          show={isShowPerson}
          noOfPerson={noOfPerson}
          onHide={() => {
            setIsShowPerson(false);
          }}
          handleClick={(personArray) => {
            setNoOfPerson(personArray);
          }}
          serviceId={atob(serviceId)}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
        />
      )}
      {isErrorVisible && (
        <GlobalModel
          show={isErrorVisible}
          onHide={() => {
            setIsErrorVisible(false);
          }}
          onRemoveClick={() => {
            setIsErrorVisible(false);
          }}
          title={isErrorMessage.title}
          bodyMessage={isErrorMessage.message}
          buttonTitle={isErrorMessage.buttonTitle}
          buttonType="normal"
        />
      )}
      <Footer />
    </>
  );
};

export default ServiceDetail;
