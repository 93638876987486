import { Col, Row } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../home-page-footer/home-page-footer.component";
import {
  FETCH_PRODUCT_WITH_FILTER_SORT,
  FETCH_USER_REFERRAL_MARKETPLACE_PROMOTION,
} from "../../graphQL/products/products";
import ProductList from "../product-list-design/product-list-design.component";
import FilterSortTabletMobile from "../filter-sort-tablet-mobile/filter-sort-tablet-mobile.component";
import FilterSortColumn from "../filter-sort-column/filter-sort-column.component";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import "./product-list.style.css";
import Pagination from "../shared/pagination/pagination.component";
import SeacrhItemNotFound from "../search-item-not-found/search-item-not-found.component";
import BreadcrumbsItemList from "../breadcrumbs/breadcrumbs-item-list.component";
import PageTitle from "../page-title/page-title.component";
import SearchTitleWithCount from "../search-title-with-item-count/search-title-with-item-count.component";
import FilterItemNotFound from "../filter-item-not-found/filter-item-not-found.component";
import ReferralBanner from "../referral-banner/referral-banner.component";
import { isUserLoggedIn } from "../../store/user/user.selector";
import { useSelector } from "react-redux";

const ProductServiceList = () => {
  window.scrollTo(0, 0);
  const params = useParams();
  const { searchedKeyword, categoryName } = params;
  const [isFliterDeals, setIsFilterDeals] = useState({});
  const [isSortValue, setIsSortValue] = useState("Featured");
  const [searchedText, setSearchedText] = useState("");
  const [isFliterPrice, setIsFilterPrice] = useState({});
  const [filteredData, setFilteredData] = useState({
    minPrice: "",
    maxPrice: "",
  });
  const isUserAuthenticated = useSelector(isUserLoggedIn);
  const [filterServiceType, setFilterServiceType] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 24;
  const [promotionMessage, setPromotionMessage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log("Params-------------------",categoryName)
    if (categoryName) {
      setSelectedCategory(categoryName);
    }
  }, [params]);

  useEffect(() => {
    if (searchedKeyword) {
      setSearchedText(searchedKeyword);
      // setSelectedCategory("Pets and Animals");
      setSelectedCategory("");
      setSelectedSubcategories([]);
    } else {
      setSearchedText("");
      setIsFilterDeals({});
      setIsSortValue("");
      setIsFilterPrice({});
      setFilteredData({ minPrice: "", maxPrice: "" });
    }
  }, [params]);
  // useEffect(()=>{
  //   if (isUserAuthenticated) {
  //     fetchBanner();
  //   }
  // },[isUserAuthenticated])
  const fetchBanner = async () => {
    await fetchUserReferralMarketpalcePromotion();
  };

  const [fetchUserReferralMarketpalcePromotion] = useLazyQuery(
    FETCH_USER_REFERRAL_MARKETPLACE_PROMOTION,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchUserReferralMarketpalcePromotion) {
          const referralMessage = data.fetchUserReferralMarketpalcePromotion;
          // console.log("Referral message-----", referralMessage);
          setPromotionMessage(referralMessage);
          setIsLoading(false);
        }
      },
      onError(error) {
        console.log("Marketplace Promotion", error.message);
        setIsLoading(false);
      },
    }
  );

  const handleOnChangeFilterPrice = (id) => {
    setPageNumber(1);
    const newCheckedItems = { ...isFliterPrice };
    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }

    setIsFilterPrice(newCheckedItems);
    if (Object.keys(newCheckedItems).length !== 0) {
      const { minValue, maxValue } = calculateMinMax(newCheckedItems);

      setFilteredData((previousData) => ({
        ...previousData,
        minPrice: minValue.toString(),
        maxPrice: maxValue.toString(),
      }));
    } else {
      setIsFilterPrice({});
      setFilteredData((previousData) => ({
        ...previousData,
        minPrice: "",
        maxPrice: "",
      }));
    }
  };

  const calculateMinMax = (selectedValue) => {
    const numericalRanges = Object.keys(selectedValue).map((key) => {
      const [min, max] = key.split("-").map(Number);
      return { min, max };
    });

    // Find the minimum and maximum values from the numerical ranges
    let minValue = numericalRanges[0].min || 0;
    let maxValue = numericalRanges[0].max;
    numericalRanges.forEach((range) => {
      if (range.min < minValue) {
        minValue = range.min;
      }
      if (range.max > maxValue || isNaN(range.max)) {
        maxValue = range.max;
      }
    });
    // let minValue = 0;
    // let maxValue = 0;
    return { minValue, maxValue };
  };

  const handleOnChangeFilterDeals = (id) => {
    setPageNumber(1);
    const newCheckedItems = { ...isFliterDeals };
    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }
    setIsFilterDeals(newCheckedItems);
    if (Object.keys(newCheckedItems).length !== 0) {
      setFilteredData((previousData) => ({
        ...previousData,
        discount: "discount",
      }));
    } else {
      setIsFilterDeals({});
      setFilteredData((previousData) => ({
        ...previousData,
        discount: "",
      }));
    }
  };

  const { loading, error, data } = useQuery(FETCH_PRODUCT_WITH_FILTER_SORT, {
    variables: {
      filterBy: filteredData,
      sortBy: isSortValue,
      searchedKeyword: searchedText,
      pageNumber: pageNumber,
      pageSize: pageSize,
      category: selectedCategory,
      subCategories: selectedSubcategories,
    },
  });

  if (error) return <p>Error: {error.message}</p>;
  const totalCount = Math.ceil(
    data?.fetchProductsWithFilterAndSort?.totalCount / pageSize
  );

  const handleCheckChange = (e) => {
    setPageNumber(1);
    setIsSortValue(e.target.value);
  };

  const resetFilterSort = () => {
    setIsFilterPrice({});
    setIsFilterDeals({});
    setFilteredData({ minPrice: "", maxPrice: "" });
    setIsSortValue("");
    setSearchedText("");
    setPageNumber(1);
    if (selectedCategory !== "Pets and Animals") {
      setSelectedCategory("Pets and Animals");
      setSelectedSubcategories([]);
    }
  };

  const handleButtonClick = (
    selectedFiltered,
    selectedSort,
    selectedSubcategories,
    mobileFilterServiceType,
    isMobileFliterPrice,
    isMobileFliterDeals
  ) => {
    setPageNumber(1);
    setFilteredData(selectedFiltered);
    setIsFilterDeals(isMobileFliterDeals);
    setIsFilterPrice(isMobileFliterPrice);
    setIsSortValue(
      selectedSort === "Featured"
        ? ""
        : selectedSort === "Price (low-high)"
        ? "price"
        : selectedSort === "Price (high-low)"
        ? "-price"
        : ""
    );
    setSelectedSubcategories(selectedSubcategories);
  };
  return (
    <>
      {data?.fetchProductsWithFilterAndSort?.products.length === 0 &&
      searchedText &&
      !filteredData.minPrice ? (
        <SeacrhItemNotFound searchedText={searchedText} type={"Products"} />
      ) : (
        <div style={{ width: "99%", marginTop: "2%" }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              className="scrollable-product-column"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BreadcrumbsItemList
                  resetFilterSort={resetFilterSort}
                  selectedCategory={selectedCategory}
                  type={"Products"}
                />
                <PageTitle title={"Products"} />
              </div>
              <SearchTitleWithCount
                searchedText={searchedText}
                itemCount={data?.fetchProductsWithFilterAndSort?.totalCount}
              />
            </Col>
            {/* {promotionMessage.message !== "" && !isLoading && isUserAuthenticated ? (
              <ReferralBanner
                className="referral-banner-for-desktop"
                promotionMessage={promotionMessage}
                isLoading={isLoading}
              />
            ) : null} */}
            <FilterSortColumn
              isFliterPrice={isFliterPrice}
              isFliterDeals={isFliterDeals}
              setIsFilterDeals={setIsFilterDeals}
              setIsFilterPrice={setIsFilterPrice}
              setFilteredData={setFilteredData}
              handleOnChangeFilterPrice={handleOnChangeFilterPrice}
              handleOnChangeFilterDeals={handleOnChangeFilterDeals}
              isSortValue={isSortValue}
              handleCheckChange={handleCheckChange}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              setSelectedSubcategories={setSelectedSubcategories}
              selectedSubcategories={selectedSubcategories}
              setPageNumber={setPageNumber}
              searchedText={searchedText}
              type={"Products"}
              resetFilterSort={resetFilterSort}
              filterServiceType={filterServiceType}
              handleLocationChange={() => {}}
              serviceLocationArray={{}}
            />
            {/* <Col lg={12} md={12} sm={12} xs={12}> */}
            {/* </Col> */}
            <FilterSortTabletMobile
              categoryName={categoryName}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              setSelectedSubcategories={setSelectedSubcategories}
              selectedSubcategories={selectedSubcategories}
              searchedText={searchedText}
              handleButtonClick={handleButtonClick}
              isSortValue={isSortValue}
              handleCheckChange={handleCheckChange}
              type={"Products"}
              isFliterPrice={isFliterPrice}
              isFliterDeals={isFliterDeals}
              setIsFilterDeals={setIsFilterDeals}
              setIsFilterPrice={setIsFilterPrice}
              resetFilterSort={resetFilterSort}
              filterServiceType={filterServiceType}
            />
            {promotionMessage.message !== "" && !isLoading ? (
              <ReferralBanner
                className="referral-banner-for-tablet-mobile"
                promotionMessage={promotionMessage}
                isLoading={isLoading}
              />
            ) : null}
            <Col
              xl={10}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="scrollable-product-column"
            >
              <Row className="product-service-list-tablet-mobile">
                {loading ? (
                  <LoadingSpinner />
                ) : data?.fetchProductsWithFilterAndSort?.products.length ===
                  0 ? (
                  <FilterItemNotFound
                    resetFilterSort={resetFilterSort}
                    displayFilterMessage={
                      (!searchedText && filteredData.minPrice) ||
                      (searchedText && filteredData.minPrice)
                        ? true
                        : false
                    }
                  />
                ) : (
                  data?.fetchProductsWithFilterAndSort?.products.map(
                    (productList, index) => {
                      return (
                        <ProductList
                          key={index}
                          productList={productList}
                          index={index}
                          type={"Products"}
                        />
                      );
                    }
                  )
                )}
              </Row>
            </Col>
            <Col xl={2} lg={0} md={0} sm={12} xs={12}></Col>
            <Col
              xl={10}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "2%" }}
            >
              {!loading &&
              data?.fetchProductsWithFilterAndSort?.products.length !== 0 ? (
                <Pagination
                  pageNumber={pageNumber}
                  totalPages={totalCount}
                  siblings={1}
                  itemsPerPage={pageSize}
                  leftAndRightSkip={3}
                  onPageChange={(page) => {
                    setPageNumber(page);
                  }}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ProductServiceList;
