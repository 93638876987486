export const subtotal = "Subtotal";
export const savingUnlockFee = "Saving unlock Fees";
export const additionaldiscount = "Additional discount";
export const REFERRAL_CREDIT = "Referral credit";
export const additionalPointTax = "Additional points tax";
export const autoShip = "Autoship discount";
export const coupon = "Autoship discount";
export const arps = "ARPs";
export const handlingFees = "Handling Fees";
export const tax = "Tax";
export const total = "Total";
