import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import CustomButton from "../button/button.component";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  errorRed,
  netural400,
  neturalBlack,
  neutral200,
  primaryOrange,
  success500,
} from "../../utils/colors";
import "./checkout-page.style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAutoshipCartCheckoutByBusinessId,
  selectCartCheckoutByBusinessId,
  selectServiceCartCheckoutByBusinessId,
} from "../../store/cart/cart.selector";
import {
  selectCurrentUser,
  selectCurrentUserShippingAddress,
} from "../../store/user/user.selector";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import OrderSummary from "../order-summary/order-summary.component";
import PaymentModal from "../payment-modal/payment-modal.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_PAYMENT_INTENT,
  CREATE_PRODUCT_SUBSCRIPTION,
  VALIDATE_ORDER_INFORMATION,
} from "../../graphQL/cart/cart.query";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../utils/stripe/stripe.utils";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { VERIFY_AVAILABLE_SLOT_SPOTS } from "../../graphQL/services/services";
import { isEmptyObject } from "../../utils/constants";
import {
  DISPLAY_REFERRAL_CHECKOUT_PROMOTION,
  FETCH_BUSINESS_TERMS_AND_POLICY_INFO_BY_ID,
  FETCH_CHECK_OUT_SUCCESS_BANNER,
  FETCH_CHECK_OUT_TOPUP_BANNER,
  FETCH_CHECKOUT_REMOVE_MODEL,
  VALIDATE_PROMOTIONAL_CODE,
} from "../../graphQL/products/products";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdErrorOutline, MdLockOutline } from "react-icons/md";
import TermsAndPrivacyModel from "../terms-and-privacy-model/terms-and-privacy-model.component";
import GlobalModel from "../global-model/global-model.component";
import AdditionalBanner from "../global-banner/global-banner.component";
import DiscountAppliedBanner from "../global-banner/global-discount-applied-banner.component";
import { setUserCart, updateCart } from "../../store/cart/cart.action";
import ReferralCheckoutBanner from "../referral-checkout-banner/referral-checkout-banner.component";

const CheckoutPage = () => {
  const params = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const itemType = searchParams.get("type");
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [sellingPlan, setSellingPlan] = useState({});
  const [service, setService] = useState({});
  const [serviceBookingInfo, setServiceBookingInfo] = useState({});
  const [serviceDurationHour, setServiceDurationHour] = useState(0);
  const [serviceDurationMinute, setServiceDurationMinute] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [stripeOption, setStripeOption] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [couponCodeError, setCouponCodeError] = useState("");
  const [promotionalCode, setPromotionalCode] = useState({});
  const [additionalDiscount, setAdditionalDiscount] = useState(false);
  const [isVisibleBanner, setIsVisibleBanner] = useState(false);
  const [messageTemplate, setMessageTemplate] = useState("");
  const [displayTermsAndPrivacyText, setDisplayTermsAndPrivacyText] =
    useState(false);
  const [termsAndPrivacy, setTermsAndPrivacy] = useState({
    termsAndConditions: "",
    privacyPolicy: "",
  });
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [bannerInfo, setBannerInfo] = useState({});
  const [bannerSuccessMessage, setBannerSuccessMessage] = useState({});
  const [bannerRemoveMessage, setBannerRemoveMessage] = useState({});
  const [isVisibleCancelModal, setISVisibleCancelModal] = useState(false);
  const [checkountInfo, setCheckoutInfo] = useState({
    subTotal: 0,
    serviceFees: 0,
    additionalDiscount: 0,
    additionalPointsTax: 0,
    autoShipDiscount: 0,
    referralCredit: 0,
    couponCode: 0,
    discountTotal: 0,
    taxAmount: 0,
    finalCheckoutTotal: 0,
  });
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState({
    title: "",
    message: "",
    buttonTitle: "",
  });
  const [referralPromotion, setReferralPromotion] = useState({});
  const [applyReferral, setApplyReferral] = useState(false);

  let selectedCheckoutCart = useSelector(
    selectCartCheckoutByBusinessId(params.state)
  );
  const selectedCheckoutAutoShipCart = useSelector(
    selectAutoshipCartCheckoutByBusinessId(params.state)
  );
  const selectedServiceCheckout = useSelector(
    selectServiceCartCheckoutByBusinessId(params.state)
  );
  const userShippingAddress = useSelector(selectCurrentUserShippingAddress);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    // console.log("Checkout-----------------", itemType);
    if (!params.state || !itemType) {
      navigation("/");
      return;
    }
    if (
      itemType === "product" &&
      selectedCheckoutCart &&
      !isEmptyObject(selectedCheckoutCart)
    ) {
      // console.log("Final data product-----------", selectedCheckoutCart);
      setProducts(selectedCheckoutCart.items);
      getCheckoutInfo(selectedCheckoutCart.items);
      getBusinessTermsAndPrivacy(selectedCheckoutCart.businessId);
      displayReferralCheckoutPromotion();
      setIsLoading(false);
    } else if (
      itemType === "service" &&
      !isEmptyObject(selectedServiceCheckout)
    ) {
      //use service variables
      // console.log("Final data service-----------", selectedServiceCheckout);
      setService(selectedServiceCheckout);
      setServiceBookingInfo(selectedServiceCheckout.bookingData);
      setServiceDurationHour(selectedServiceCheckout.duration.hours);
      setServiceDurationMinute(selectedServiceCheckout.duration.minutes);
      getServiceCheckoutInfo(selectedServiceCheckout);
      getBusinessTermsAndPrivacy(selectedServiceCheckout.businessId);
      displayReferralCheckoutPromotion();
      setIsLoading(false);
    } else if (itemType === "autoship") {
      // console.log(
      //   "Final data autoship-----------",
      //   selectedCheckoutAutoShipCart
      // );
      setProducts(selectedCheckoutAutoShipCart.items);
      getCheckoutInfoAutoShip(selectedCheckoutAutoShipCart.items);
      setSellingPlan(selectedCheckoutAutoShipCart.sellingPlan);
      getBusinessTermsAndPrivacy(selectedCheckoutAutoShipCart.businessId);
      setIsLoading(false);
    } else {
      navigation("/");
    }
  }, [params, itemType]);

  const [displayReferralCheckoutPromotion] = useLazyQuery(
    DISPLAY_REFERRAL_CHECKOUT_PROMOTION,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log("Error in displayReferralCheckoutPromotion", error);
      },
      onCompleted(data) {
        if (data && data.displayReferralCheckoutPromotion) {
          const referralMessage = data.displayReferralCheckoutPromotion;
          // console.log("Referral message", referralMessage);
          setReferralPromotion(referralMessage);
        }
      },
    }
  );

  const getbannerInfo = (subTotal) => {
    // console.log(
    //   "Final data product-----------",
    //   selectedCheckoutCart,
    //   subTotal,
    //   // currentUser.subscription.subscriptionDiscount
    //   currentUser
    // );
    fetchCheckoutTopUpBanner({
      variables: {
        userId: currentUser._id,
        sellerId:
          itemType === "service"
            ? selectedServiceCheckout.businessId
            : selectedCheckoutCart.businessId,
        checkoutInfo: {
          subtotal: parseFloat(subTotal),
          shippingCost:
            itemType === "service"
              ? 0.0
              : parseFloat(selectedCheckoutCart.shippingCost),
          taxRate:
            itemType === "service"
              ? parseFloat(selectedServiceCheckout.taxRate)
              : parseFloat(selectedCheckoutCart.taxRate),
          subscriptionDiscount: parseFloat(
            currentUser.subscription.subscriptionDiscount
          ),
        },
        requestFrom: "ecommerce",
      },
    });
  };

  const getSubTotal = (products) => {
    return products
      .reduce(
        (subTotal, item) =>
          subTotal + parseInt(item.quantity) * parseFloat(item.finalTotal),
        0.0
      )
      .toFixed(2);
  };

  const getServiceSubTotal = (service) => {
    return (
      parseInt(service.bookingData.noOfSpots) *
      parseFloat(service.finalTotal).toFixed(2)
    );
  };

  const getDiscountedPrice = (subTotal) => {
    return (
      (subTotal * parseInt(currentUser.subscription.subscriptionDiscount)) /
      100
    ).toFixed(2);
  };

  const getAutoShipDiscount = (subTotal, sellingPlan) => {
    return ((subTotal * parseInt(sellingPlan.discount)) / 100).toFixed(2);
  };
  const getTaxAmount = (taxableTotal) => {
    return (
      (taxableTotal *
        parseFloat(
          itemType === "product"
            ? selectedCheckoutCart?.taxRate
            : itemType === "autoship"
            ? selectedCheckoutAutoShipCart?.taxRate
            : selectedServiceCheckout?.taxRate
        )) /
      100
    ).toFixed(2);
  };
  const getTaxAmountAutoShip = (taxableTotal) => {
    return (
      (taxableTotal *
        parseFloat(
          itemType === "product"
            ? selectedCheckoutAutoShipCart?.taxRate
            : selectedCheckoutAutoShipCart?.taxRate
        )) /
      100
    ).toFixed(2);
  };

  const getFinalTotal = (taxableTotal, taxAmount) => {
    return (taxableTotal + taxAmount).toFixed(2);
  };

  const calculateReferralPoints = (type) => {
    const subTotal = checkountInfo.subTotal;
    const newSubtotal = type
      ? parseFloat(subTotal) -
        parseFloat(referralPromotion?.referralPromotion?.rewardAmount)
      : subTotal;
    const discountedTotal = getDiscountedPrice(parseFloat(newSubtotal));
    const taxableTotal =
      parseFloat(newSubtotal) -
      parseFloat(discountedTotal) +
      parseFloat(selectedCheckoutCart?.shippingCost);
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    if (type) {
      const referral = {
        referralCredit: referralPromotion?.referralPromotion?.rewardAmount,
        businessId: selectedCheckoutCart.businessId,
      };
      dispatch(updateCart(referral));
    } else {
      const referral = {
        referralCredit: null,
        businessId: selectedCheckoutCart.businessId,
      };
      dispatch(updateCart(referral));
    }
    setCheckoutInfo({
      ...checkountInfo,
      referralCredit: parseFloat(
        referralPromotion?.referralPromotion?.rewardAmount
      ),
      autoShipDiscount: 0,
      discountTotal: parseFloat(discountedTotal).toFixed(2),
      taxAmount: parseFloat(taxAmount).toFixed(2),
      finalCheckoutTotal: parseFloat(finalCheckoutTotal).toFixed(2),
    });
  };

  const getCheckoutInfoAutoShip = (products) => {
    const subTotal = getSubTotal(products);
    const autoShipDiscount = getAutoShipDiscount(
      parseFloat(subTotal),
      selectedCheckoutAutoShipCart.sellingPlan
    );
    const newSubTotal = parseFloat(subTotal - autoShipDiscount);
    // console.log("Discount price--------------", autoShipDiscount);
    const discountedTotal = getDiscountedPrice(
      parseFloat(autoShipDiscount > 0 ? newSubTotal : subTotal)
    );
    const taxableTotal =
      parseFloat(autoShipDiscount > 0 ? newSubTotal : subTotal) -
      parseFloat(discountedTotal) +
      parseFloat(selectedCheckoutAutoShipCart?.shippingCost);
    const taxAmount = getTaxAmountAutoShip(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      ...checkountInfo,
      subTotal: subTotal,
      autoShipDiscount: autoShipDiscount,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
  };

  const getCheckoutInfo = (products) => {
    const subTotal = getSubTotal(products);
    const discountedTotal = getDiscountedPrice(parseFloat(subTotal));
    const taxableTotal =
      parseFloat(subTotal) -
      parseFloat(discountedTotal) +
      parseFloat(selectedCheckoutCart?.shippingCost);
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      ...checkountInfo,
      subTotal: subTotal,
      autoShipDiscount: 0,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
    getbannerInfo(subTotal);
  };

  const getCheckoutInfoBanner = (
    topUpAmount,
    discount,
    additionalPointsTax
  ) => {
    if (itemType === "product") {
      const subTotal = getSubTotal(products);
      const newSubtotal = parseFloat(subTotal) - parseFloat(discount);
      const discountedTotal = getDiscountedPrice(parseFloat(subTotal));
      const taxableTotal =
        parseFloat(newSubtotal) -
        parseFloat(discountedTotal) +
        parseFloat(selectedCheckoutCart?.shippingCost);
      const taxAmount = getTaxAmount(taxableTotal);
      const finalCheckoutTotal = getFinalTotal(
        taxableTotal,
        parseFloat(taxAmount)
      );
      const finalTotal =
        parseFloat(finalCheckoutTotal) +
        parseFloat(additionalPointsTax) +
        parseFloat(topUpAmount);
      setCheckoutInfo({
        ...checkountInfo,
        subTotal: subTotal,
        serviceFees: topUpAmount,
        additionalDiscount: discount,
        additionalPointsTax: additionalPointsTax,
        autoShipDiscount: 0,
        discountTotal: discountedTotal,
        taxAmount: taxAmount,
        finalCheckoutTotal: Number(finalTotal).toFixed(2),
      });
    } else if (itemType === "service") {
      const subTotal = getServiceSubTotal(service);
      const newSubtotal = parseFloat(subTotal) - parseFloat(discount);
      const discountedTotal = getDiscountedPrice(parseFloat(subTotal));
      const taxableTotal =
        parseFloat(newSubtotal) - parseFloat(discountedTotal);
      const taxAmount = getTaxAmount(taxableTotal);
      const finalCheckoutTotal = getFinalTotal(
        taxableTotal,
        parseFloat(taxAmount)
      );
      const finalTotal =
        parseFloat(finalCheckoutTotal) +
        parseFloat(additionalPointsTax) +
        parseFloat(topUpAmount);
      setCheckoutInfo({
        ...checkountInfo,
        subTotal: subTotal,
        serviceFees: topUpAmount,
        additionalDiscount: discount,
        additionalPointsTax: additionalPointsTax,
        autoShipDiscount: 0,
        couponCode: 0,
        discountTotal: discountedTotal,
        taxAmount: taxAmount,
        finalCheckoutTotal: finalTotal,
      });
    }
  };

  const getServiceCheckoutInfo = (service) => {
    const subTotal = getServiceSubTotal(service);
    const discountedTotal = getDiscountedPrice(parseFloat(subTotal));
    const taxableTotal = parseFloat(subTotal) - parseFloat(discountedTotal);
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      ...checkountInfo,
      subTotal: subTotal,
      autoShipDiscount: 0,
      couponCode: 0,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
    getbannerInfo(subTotal);
  };

  const [fetchCheckoutTopUpBanner, { loading: loadingCheckoutTopupBanner }] =
    useLazyQuery(FETCH_CHECK_OUT_TOPUP_BANNER, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        // console.log("Error in fetchCheckoutTopUpBanner", error);
        setIsLoading(false);
      },
      onCompleted: (data) => {
        if (data && data.fetchCheckoutTopUpBanner) {
          const bannerValue = data.fetchCheckoutTopUpBanner;
          const bannerDetails = data.fetchCheckoutTopUpBanner.details;
          setMessageTemplate(bannerValue.message);
          setBannerInfo(bannerValue);
          // getCheckoutInfoBanner(
          //   bannerDetails.topUpAmount,
          //   bannerDetails.discount
          // );
          // setAdditionalDiscount(true);
          setIsVisibleBanner(true);
          // console.log("Banner value---------------", bannerValue);
        } else {
          setIsVisibleBanner(false);
          // console.log("Banner value else---------------");
        }
      },
    });

  const [
    fetchCheckoutSuccessBanner,
    { loading: loadingCheckoutSuccessBanner },
  ] = useLazyQuery(FETCH_CHECK_OUT_SUCCESS_BANNER, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      // console.log("Error in fetchCheckoutSuccessBanner", error);
      setIsLoading(false);
      setAdditionalDiscount(false);
    },
    onCompleted: (data) => {
      if (data && data.fetchCheckoutSuccessBanner) {
        const bannerSuccessValue = data.fetchCheckoutSuccessBanner;
        setBannerSuccessMessage(bannerSuccessValue);
        setAdditionalDiscount(true);
        // console.log("Success Banner value---------------", bannerSuccessValue);
      } else {
        // setIsVisibleBanner(false);
        setAdditionalDiscount(false);
        // console.log("Success Banner value else---------------");
      }
    },
  });

  const handleRemoveAdditionalDiscount = () => {
    fetchCheckoutRemoveModel({
      variables: {
        discountAmount: parseFloat(bannerInfo.details.discount),
        type: "",
      },
    });
  };

  const [fetchCheckoutRemoveModel, { loading: loadingCheckoutRemoveModel }] =
    useLazyQuery(FETCH_CHECKOUT_REMOVE_MODEL, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        // console.log("Error in fetchCheckoutRemoveModel", error);
        setIsLoading(false);
      },
      onCompleted: (data) => {
        if (data && data.fetchCheckoutRemoveModel) {
          const bannerValue = data.fetchCheckoutRemoveModel;
          setBannerRemoveMessage(bannerValue.message);
          setISVisibleCancelModal(true);
        }
      },
    });

  const getBusinessTermsAndPrivacy = async (businessId) => {
    await fetchBusinessTermsAndPrivacyById({
      variables: {
        businessId,
      },
    });
  };

  const [fetchBusinessTermsAndPrivacyById] = useLazyQuery(
    FETCH_BUSINESS_TERMS_AND_POLICY_INFO_BY_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        // console.log("Error in fetchBusinessTermsAndPrivacyById", error);
        setIsLoading(false);
        setDisplayTermsAndPrivacyText(false);
      },
      onCompleted: async (data) => {
        // console.log("fetchBusinessTermsAndPrivacyById", data);
        if (data.fetchBusinessTermsAndPrivacyById.success) {
          const { termsAndConditions, privacyPolicy } =
            data.fetchBusinessTermsAndPrivacyById.data;
          // console.log(
          //   "termsAndConditions and privacyPolicy",
          //   termsAndConditions,
          //   privacyPolicy
          // );
          setTermsAndPrivacy({ termsAndConditions, privacyPolicy });
          setDisplayTermsAndPrivacyText(true);
        } else {
          setDisplayTermsAndPrivacyText(false);
        }
        setIsLoading(false);
      },
    }
  );

  const [validatePromotionalCode, { loading: promoCodeLoading }] = useLazyQuery(
    VALIDATE_PROMOTIONAL_CODE,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.validatePromotionalCode) {
          // console.log("code--------------", added_promocode);
          let promoCodeValue = data.validatePromotionalCode;
          if (promoCodeValue.isValid) {
            delete promoCodeValue.promotionalCode.__typename;
            setPromotionalCode(promoCodeValue.promotionalCode);
            calculatePromoCode(promoCodeValue);
            setIsValidCoupon(!isValidCoupon);
          } else {
            setCouponCodeError(promoCodeValue.message);
          }
          // console.log("all value--------------", promoCodeValue);
        }
      },
      onError: (e) => {
        // console.log("all error--------------", e);
        toast.error(e, {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      },
    }
  );

  const calculatePromoCode = (promoCode) => {
    const newSubtotal =
      checkountInfo.autoShipDiscount > 0
        ? checkountInfo.subTotal - checkountInfo.autoShipDiscount
        : checkountInfo.subTotal - promoCode.promotionalCode.discountedAmount;
    const discountedTotal = getDiscountedPrice(
      parseFloat(
        checkountInfo.autoShipDiscount > 0
          ? promoCode.promotionalCode.discountedAmount
          : newSubtotal
      )
    );
    const taxableTotal =
      parseFloat(
        checkountInfo.autoShipDiscount > 0
          ? promoCode.promotionalCode.discountedAmount
          : newSubtotal
      ) -
      parseFloat(discountedTotal) +
      parseFloat(
        checkountInfo.autoShipDiscount > 0
          ? selectedCheckoutAutoShipCart?.shippingCost
          : selectedCheckoutCart?.shippingCost
      );
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      ...checkountInfo,
      couponCode: promoCode.promotionalCode.discountedAmount,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
  };

  const handlePromoCodeClick = async () => {
    setCouponCodeError("");
    await validatePromotionalCode({
      variables: {
        promotionalCodeInputs: {
          promotionalCode: couponCode,
          originalAmount: parseFloat(
            checkountInfo.autoShipDiscount > 0
              ? checkountInfo.subTotal - checkountInfo.autoShipDiscount
              : checkountInfo.subTotal
          ),
        },
      },
    });
  };

  const handlePromoCodeCancel = () => {
    // console.log("Calculate---------------",parseFloat(checkountInfo.finalCheckoutTotal) + parseFloat(checkountInfo.couponCode))
    const newSubtotal = parseFloat(
      checkountInfo.subTotal + checkountInfo.couponCode
    );
    const discountedTotal = getDiscountedPrice(parseFloat(newSubtotal));
    const taxableTotal =
      parseFloat(newSubtotal) -
      parseFloat(discountedTotal) +
      parseFloat(
        checkountInfo.autoShipDiscount > 0
          ? selectedCheckoutAutoShipCart?.shippingCost
          : selectedCheckoutCart?.shippingCost
      );
    const taxAmount = getTaxAmount(taxableTotal);
    const finalCheckoutTotal = getFinalTotal(
      taxableTotal,
      parseFloat(taxAmount)
    );
    setCheckoutInfo({
      ...checkountInfo,
      couponCode: 0,
      discountTotal: discountedTotal,
      taxAmount: taxAmount,
      finalCheckoutTotal: finalCheckoutTotal,
    });
    setIsValidCoupon(!isValidCoupon);
  };
  const [createPaymentIntent] = useLazyQuery(CREATE_PAYMENT_INTENT, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError: (e) => {
      alert("Something went wrong...", e.message);
      // console.log("ERROR FRONTE END", e);
      setIsPaymentLoading(false);
    },
    onCompleted: async (data) => {
      // console.log("Client Secret", data);
      if (data && data.createPaymentIntent) {
        let paymentInfo = data.createPaymentIntent;
        const options = {
          // passing the client secret obtained in step 3
          clientSecret: paymentInfo.paymentIntent,
          // Fully customizable with appearance API.
          appearance: {
            theme: "stripe",
            variables: {
              colorPrimary: primaryOrange,
              colorBackground: "#ffffff",
              colorText: "#30313d",
              colorDanger: "#df1b41",
              fontFamily: "Ideal Sans, system-ui, sans-serif",
              // spacingUnit: "2px",
              borderRadius: "4px",
              // See all possible variables below
            },
          },
        };
        setStripeOption(options);
        setIsPaymentLoading(false);
        setShowPaymentModal(true);
      }
      setIsPaymentLoading(false);
    },
  });

  const [createProductSubscription] = useMutation(CREATE_PRODUCT_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError: (e) => {
      alert("Something went wrong...", e.message);
      // console.log("ERROR FRONTE END", e);
      setIsPaymentLoading(false);
    },
    onCompleted: async (data) => {
      // console.log("Client Secret", data);
      if (data && data.createProductSubscription) {
        let paymentInfo = data.createProductSubscription;
        const options = {
          // passing the client secret obtained in step 3
          clientSecret: paymentInfo.paymentIntent,
          // Fully customizable with appearance API.
          appearance: {
            theme: "stripe",
            variables: {
              colorPrimary: primaryOrange,
              colorBackground: "#ffffff",
              colorText: "#30313d",
              colorDanger: "#df1b41",
              fontFamily: "Ideal Sans, system-ui, sans-serif",
              // spacingUnit: "2px",
              borderRadius: "4px",
              // See all possible variables below
            },
          },
        };
        setStripeOption(options);
        setIsPaymentLoading(false);
        setShowPaymentModal(true);
      }
      setIsPaymentLoading(false);
    },
  });

  const [validateOrder] = useMutation(VALIDATE_ORDER_INFORMATION, {
    onCompleted: (data) => {
      if (data && data.validateOrder.success) {
        initializePaymentRequest();
      } else {
        const itemAvaibility = data.validateOrder.itemAvaibility;
        setIsErrorVisible(true);
        setIsErrorMessage({
          title: itemAvaibility[0].errorTitle,
          message: itemAvaibility[0].message,
          buttonTitle: itemAvaibility[0].buttonTitle,
        });
      }
    },
    onError: (error) => {
      setIsPaymentLoading(false);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    },
  });

  const initializePaymentRequest = async () => {
    if (itemType === "autoship") {
      const customerEmail = userShippingAddress.email;
      const cartItems = JSON.parse(
        JSON.stringify(selectedCheckoutAutoShipCart)
      );
      const productInformation = cartItems.items.map((item) => {
        // console.log(item, "COEMFIANFSKDFMSKDFNS");
        return {
          title: item.title,
          variantCombination: item?.variantCombination
            ? item?.variantCombination
            : null,
          currency: "usd",
          price: parseFloat(checkountInfo.finalCheckoutTotal),
          frequencyType: cartItems.sellingPlan.frequencyType,
          frequency: cartItems.sellingPlan.billingCycle,
          planName: cartItems.sellingPlan.planName,
        };
      });
      const createProductSubscriptionInputs = {
        customerEmail,
        planId: process.env.REACT_APP_AUTOSHIP_STRIPE_PLAN_ID,
        productInformation,
      };
      await createProductSubscription({
        variables: {
          createProductSubscriptionInputs,
        },
      });
    } else {
      let userInfo = {
        email: userShippingAddress.email,
      };
      await createPaymentIntent({
        variables: {
          userInfo,
          price: checkountInfo.finalCheckoutTotal.toString(),
        },
      });
    }
  };
  const processProductPurchase = async () => {
    const cartItems = JSON.parse(
      JSON.stringify(
        itemType === "autoship"
          ? selectedCheckoutAutoShipCart
          : selectedCheckoutCart
      )
    );
    const itemArray = cartItems.items.map((item) => {
      return {
        itemId: item._id,
        variantCombination: item?.variantCombination
          ? item?.variantCombination
          : null,
        quantity: item.quantity.toString(),
        type: itemType === "autoship" ? "autoship" : "Product",
      };
    });
    await validateOrder({
      variables: {
        address: userShippingAddress.address,
        itemQuantity: itemArray,
      },
    });
  };

  const [verifyAvailableSlotSpots] = useMutation(VERIFY_AVAILABLE_SLOT_SPOTS);

  const processServicePurchase = async () => {
    let result = await verifyAvailableSlotSpots({
      variables: {
        serviceId: service._id,
        selectedDate: serviceBookingInfo.bookingDate,
        selectedStartTime: serviceBookingInfo.serviceSatrtTime,
        selectedEndTime: serviceBookingInfo.serviceEndTime,
        spots: serviceBookingInfo.noOfSpots,
      },
    });
    if (result.data.verifyAvailableSlotSpots.success === true) {
      initializePaymentRequest();
    } else {
      // setAvailabeSlots(result.data.verifyAvailableSlotSpots.remainingCapacity);
      const itemAvaibility =
        result.data.verifyAvailableSlotSpots.itemAvaibility;
      setIsErrorVisible(true);
      setIsErrorMessage({
        title: itemAvaibility[0].errorTitle,
        message: itemAvaibility[0].message,
        buttonTitle: itemAvaibility[0].buttonTitle,
      });
      setIsPaymentLoading(false);
    }
  };
  const handlePaymentClick = async () => {
    setIsPaymentLoading(true);
    if (itemType === "product" || itemType === "autoship") {
      // console.log("Final value-----------", selectedCheckoutCart);
      processProductPurchase();
    } else {
      processServicePurchase();
    }
  };
  return (
    <Container style={{ width: "99%" }}>
      <Row className="justify-content-center mt-4">
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="justify-content-center"
          style={{ display: "flex" }}
        >
          <CustomLabel style={{ fontSize: "22px", fontWeight: "bold" }}>
            Checkout
          </CustomLabel>
        </Col>
      </Row>
      {isLoading ||
      loadingCheckoutTopupBanner ||
      loadingCheckoutSuccessBanner ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row className="justify-content-center">
            <Col md={12} lg={12}>
              <Row
                className="mb-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* Empty Column */}
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                {/* Content 1 */}
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-4">
                  {/* Content 1 */}
                  <CustomLabel style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {itemType === "product"
                      ? `Ship to`
                      : `Personal Information`}
                  </CustomLabel>
                  <Card
                    style={{
                      marginTop: "5%",
                      padding: "4%",
                      borderRadius: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <CustomLabel
                        style={{ color: netural400, fontSize: "16px" }}
                      >
                        {`${userShippingAddress.firstName} ${userShippingAddress.lastName}`}
                      </CustomLabel>
                      <CustomLabel
                        onClick={() => {
                          navigation(`/buy?type=${itemType}`, {
                            state: params.state,
                          });
                        }}
                        style={{
                          color: primaryOrange,
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        Edit
                      </CustomLabel>
                    </div>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontSize: "16px",
                        marginTop: "1%",
                      }}
                    >
                      {userShippingAddress.email}
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        color: netural400,
                        fontSize: "16px",
                        marginTop: "1%",
                      }}
                    >
                      {userShippingAddress.address}
                    </CustomLabel>
                  </Card>
                  {itemType === "autoship" && (
                    <div style={{ marginTop: "8%" }}>
                      <CustomLabel
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        Delivery every
                      </CustomLabel>
                      <Card
                        style={{
                          marginTop: "3%",
                          padding: "4%",
                          borderRadius: "12px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <CustomLabel
                            style={{
                              color: netural400,
                              fontSize: "16px",
                            }}
                          >
                            {products[0].billingCycle}{" "}
                            {sellingPlan.frequencyType}
                          </CustomLabel>
                        </div>
                      </Card>
                    </div>
                  )}
                  {itemType === "service" && (
                    <>
                      <div style={{ marginTop: "8%" }}>
                        <CustomLabel
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Service method
                        </CustomLabel>
                        <Card
                          style={{
                            marginTop: "3%",
                            padding: "4%",
                            borderRadius: "12px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <CustomLabel
                              style={{
                                color: netural400,
                                fontSize: "16px",
                              }}
                            >
                              {parseInt(serviceDurationHour) >= 1
                                ? serviceDurationHour + " hour"
                                : serviceDurationHour + " hours"}
                              {parseInt(serviceDurationMinute) == 0
                                ? null
                                : " " + serviceDurationMinute + " minutes"}
                              {` - ${serviceBookingInfo.serviceType}`}
                            </CustomLabel>
                          </div>
                        </Card>
                      </div>
                      <div style={{ marginTop: "8%" }}>
                        <CustomLabel
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Booking date
                        </CustomLabel>
                        <Card
                          style={{
                            marginTop: "3%",
                            padding: "4%",
                            borderRadius: "12px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <CustomLabel
                              style={{
                                color: netural400,
                                fontSize: "16px",
                              }}
                            >
                              {`${service.bookingData.bookingDate} - ${service.bookingData.serviceSatrtTime}`}
                            </CustomLabel>
                          </div>
                        </Card>
                      </div>
                    </>
                  )}
                  {itemType === "product" &&
                  !additionalDiscount &&
                  !applyReferral ? (
                    <div style={{ marginTop: "4%" }}>
                      <CustomLabel
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        Coupon code
                      </CustomLabel>
                      <InputGroup
                        style={{
                          color: "#A6A6A6",
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "2%",
                          border: "solid 1px #545454",
                          borderRadius: "12px",
                          marginTop: "2%",
                        }}
                      >
                        <Form.Control
                          required
                          style={{
                            borderRadius: "12px",
                            border: "solid 0px #545454",
                            fontSize: "18px",
                            color: neturalBlack,
                            textTransform: "uppercase",
                          }}
                          type="text"
                          value={couponCode}
                          minLength={3}
                          maxLength={30}
                          disabled={isValidCoupon || promoCodeLoading}
                          onChange={(value) => {
                            const coupon = value.target.value.toUpperCase();
                            if (coupon.trim().length !== 0) {
                              setCouponCode(coupon);
                              setCouponCodeError("");
                            } else {
                              setCouponCodeError("");
                              setCouponCode("");
                            }
                          }}
                        />
                        {promoCodeLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <div
                            style={{ marginLeft: "2%", padding: "1.5%" }}
                            onClick={() => {
                              if (isValidCoupon) {
                                handlePromoCodeCancel();
                              } else {
                                handlePromoCodeClick();
                              }
                            }}
                          >
                            {isValidCoupon ? (
                              <CustomLabel
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: primaryOrange,
                                }}
                              >
                                Remove
                              </CustomLabel>
                            ) : (
                              <CustomLabel
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: couponCode
                                    ? primaryOrange
                                    : neutral200,
                                }}
                              >
                                Apply
                              </CustomLabel>
                            )}
                          </div>
                        )}
                      </InputGroup>
                      {isValidCoupon && (
                        <CustomLabel
                          style={{
                            fontSize: "12px",
                            color: success500,
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1%",
                            marginLeft: "1%",
                          }}
                        >
                          <IoCheckmarkCircleOutline
                            color={success500}
                            size={14}
                            style={{ marginRight: "1%" }}
                          />
                          Coupon code applied
                        </CustomLabel>
                      )}
                      {couponCodeError && (
                        <CustomLabel
                          style={{
                            fontSize: "12px",
                            color: errorRed,
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1%",
                            marginLeft: "1%",
                          }}
                        >
                          <MdErrorOutline
                            color={errorRed}
                            size={14}
                            style={{ marginRight: "1%" }}
                          />
                          {couponCodeError}
                        </CustomLabel>
                      )}
                    </div>
                  ) : null}
                  <ReferralCheckoutBanner
                    referralPromotion={referralPromotion}
                    isValidCoupon={isValidCoupon}
                    isDiscountApplied={additionalDiscount}
                    checkountInfo={checkountInfo}
                    applyReferral={applyReferral}
                    setApplyReferral={setApplyReferral}
                    calculateReferralPoints={calculateReferralPoints}
                  />
                  {isVisibleBanner && !isValidCoupon && !applyReferral ? (
                    (itemType === "product" || itemType === "service") &&
                    !additionalDiscount ? (
                      <div style={{ marginTop: "8%", marginBottom: "5%" }}>
                        <CustomLabel
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Apply additional discount
                        </CustomLabel>
                        <AdditionalBanner
                          message={messageTemplate}
                          onClick={() => {
                            getCheckoutInfoBanner(
                              bannerInfo.details.topUpAmount,
                              bannerInfo.details.discount,
                              bannerInfo.details.additionalPointsTax
                            );
                            fetchCheckoutSuccessBanner({
                              variables: {
                                type: "",
                                finalTotal: parseFloat(
                                  bannerInfo.details.finalTotal
                                ),
                              },
                            });
                            // getbannerInfo(checkountInfo.subTotal);
                            // console.log(
                            //   "Updated value-------------",
                            //   selectedServiceCheckout
                            // );
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ marginTop: "8%", marginBottom: "5%" }}>
                        <CustomLabel
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Apply additional discount
                        </CustomLabel>
                        {loadingCheckoutRemoveModel ? (
                          <LoadingSpinner />
                        ) : (
                          <DiscountAppliedBanner
                            message={bannerSuccessMessage.message}
                            onClick={() => {
                              handleRemoveAdditionalDiscount();
                            }}
                          />
                        )}
                      </div>
                    )
                  ) : null}
                  {displayTermsAndPrivacyText && (
                    <div
                      className="checkout-button-laptop"
                      style={{ marginTop: "24px" }}
                    >
                      <CustomLabel
                        style={{ fontSize: 14, fontWeight: "normal" }}
                      >
                        By continuing, you agree to the business{" "}
                        <CustomLabel
                          style={{
                            textDecoration: "underline",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setIsTermsOpen(true);
                          }}
                        >
                          Terms and Conditions
                        </CustomLabel>{" "}
                        and{" "}
                        <CustomLabel
                          style={{
                            textDecoration: "underline",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setIsPrivacyOpen(true);
                          }}
                        >
                          Privacy Policy
                        </CustomLabel>
                      </CustomLabel>
                    </div>
                  )}
                  <div className="checkout-button-laptop">
                    <CustomButton
                      title={
                        itemType === "autoship"
                          ? "Subscribe"
                          : "Continue to payment"
                      }
                      processing={isPaymentLoading}
                      customStyle={{ width: "100%", fontWeight: "bold" }}
                      handleButtonClick={handlePaymentClick}
                    />
                  </div>
                </Col>
                {/* Empty Columns */}
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                {/* Content 2 */}
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="mt-4">
                  {/* Content 2 */}
                  {itemType === "product" ? (
                    <OrderSummary
                      products={products}
                      itemType={itemType}
                      pricing={checkountInfo}
                      selectedCheckoutCart={selectedCheckoutCart}
                      promotionalCode={promotionalCode}
                      applyReferral={applyReferral}
                    />
                  ) : itemType === "autoship" ? (
                    <OrderSummary
                      products={products}
                      itemType={itemType}
                      pricing={checkountInfo}
                      selectedCheckoutCart={selectedCheckoutAutoShipCart}
                      promotionalCode={promotionalCode}
                      applyReferral={applyReferral}
                    />
                  ) : (
                    <OrderSummary
                      products={[service]}
                      itemType={itemType}
                      pricing={checkountInfo}
                      selectedCheckoutCart={selectedServiceCheckout}
                      promotionalCode={promotionalCode}
                      applyReferral={applyReferral}
                    />
                  )}
                  {displayTermsAndPrivacyText && (
                    <div
                      className="checkout-button-mobile"
                      style={{ marginTop: "24px" }}
                    >
                      <CustomLabel
                        style={{ fontSize: 14, fontWeight: "normal" }}
                      >
                        By continuing, you agree to the business{" "}
                        <CustomLabel
                          style={{
                            textDecoration: "underline",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setIsTermsOpen(true);
                          }}
                        >
                          Terms and Conditions
                        </CustomLabel>{" "}
                        and{" "}
                        <CustomLabel
                          style={{
                            textDecoration: "underline",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setIsPrivacyOpen(true);
                          }}
                        >
                          Privacy Policy
                        </CustomLabel>
                      </CustomLabel>
                    </div>
                  )}
                  <div className="checkout-button-mobile">
                    <CustomButton
                      title={
                        itemType === "autoship"
                          ? "Subscribe"
                          : "Continue to payment"
                      }
                      processing={isPaymentLoading}
                      customStyle={{
                        marginLeft: "10%",
                        width: "80%",
                        fontWeight: "bold",
                      }}
                      handleButtonClick={handlePaymentClick}
                    />
                  </div>
                </Col>
                {/* Empty Column */}
                <Col
                  xs={1}
                  sm={1}
                  md={1}
                  style={{ backgroundColor: "transparent" }}
                ></Col>
              </Row>
            </Col>
          </Row>
          {isTermsOpen || isPrivacyOpen ? (
            <TermsAndPrivacyModel
              show={isTermsOpen || isPrivacyOpen}
              title={isTermsOpen ? "Terms and Conditions" : "Privacy Policy"}
              body={
                isTermsOpen
                  ? termsAndPrivacy.termsAndConditions
                  : termsAndPrivacy.privacyPolicy
              }
              onHide={() => {
                if (isTermsOpen) {
                  setIsTermsOpen(false);
                } else {
                  setIsPrivacyOpen(false);
                }
              }}
            />
          ) : null}
          {showPaymentModal && (
            <Elements stripe={stripePromise} options={stripeOption}>
              {/* <PaymentElement/> */}
              <PaymentModal
                show={showPaymentModal}
                itemType={itemType}
                promotionalCode={isValidCoupon ? promotionalCode : {}}
                cartValue={
                  itemType === "product"
                    ? selectedCheckoutCart
                    : itemType === "service"
                    ? selectedServiceCheckout
                    : selectedCheckoutAutoShipCart
                }
                price={{
                  serviceFees: checkountInfo.serviceFees,
                  additionalDiscount: checkountInfo.additionalDiscount,
                  additionalPointsTax: checkountInfo.additionalPointsTax,
                }}
                businessId={
                  itemType === "product"
                    ? selectedCheckoutCart.businessId
                    : itemType === "autoship"
                    ? selectedCheckoutAutoShipCart.businessId
                    : selectedServiceCheckout.businessId
                }
                onHide={() => {
                  setShowPaymentModal(false);
                }}
              />
            </Elements>
          )}
        </>
      )}
      {isVisibleCancelModal && (
        <GlobalModel
          show={isVisibleCancelModal}
          onHide={() => {
            setISVisibleCancelModal(false);
          }}
          onRemoveClick={() => {
            setAdditionalDiscount(false);
            getCheckoutInfoBanner(0, 0, 0);
            setISVisibleCancelModal(false);
          }}
          title="Remove additional discount?"
          bodyMessage={bannerRemoveMessage}
          buttonTitle="Remove"
          displayIcon={true}
          buttonType="error"
        />
      )}
      {isErrorVisible && (
        <GlobalModel
          show={isErrorVisible}
          onHide={() => {
            setIsErrorVisible(false);
          }}
          onRemoveClick={() => {
            setIsErrorVisible(false);
          }}
          title={isErrorMessage.title}
          bodyMessage={isErrorMessage.message}
          buttonTitle={isErrorMessage.buttonTitle}
          buttonType="normal"
        />
      )}
    </Container>
  );
};

export default CheckoutPage;
