import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbsItemList from "../breadcrumbs/breadcrumbs-item-list.component";
import PageTitle from "../page-title/page-title.component";
import SectionTitle from "../section-title/section-title.component";
import SectionBody from "../section-body/section-body.component";
import ReactPlayer from "react-player";
import {
  tutorial_business_plan,
  tutorial_data,
  tutorial_user_plan,
} from "../../utils/constants";
import Footer from "../home-page-footer/home-page-footer.component";
import PlanCard from "../subscription-plan-tutorial-card/subscription-plan-tutorial-card.component";
import { useNavigate } from "react-router-dom";

const TutorialPage = () => {
  const navigation = useNavigate();
  const handleClick = (type) => {
    navigation("/membership", { state: { type } });
  };

  return (
    <>
      <Container style={{ width: "99%", marginTop: "2%" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xl={10} lg={10} md={12} sm={12} xs={12}>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // className="scrollable-product-column"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BreadcrumbsItemList
                  resetFilterSort={() => {}}
                  type={"Tutorial"}
                />
                <PageTitle title={"Tutorial"} />
              </div>
            </Col>
            <div style={{ marginTop: "5%" }}>
              <SectionTitle title={"Customer"} />
              <div className="plans-container">
                <Row style={{ width: "100%" }}>
                  {tutorial_user_plan.map((plan, index) => (
                    <Col
                      xl={3}
                      lg={3}
                      md={5}
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                      className="mb-4"
                    >
                      <PlanCard
                        key={index}
                        planName={plan.planName}
                        saving={plan.saving}
                        originalPrice={plan.originalPrice}
                        discount={plan.discount}
                        type="user"
                        handleClick={handleClick}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>

            <div style={{ marginTop: "5%" }}>
              <SectionTitle title={"Business"} />
              <div
                className="plans-container"
                style={{ justifyContent: "normal" }}
              >
                <Row style={{ width: "100%" }}>
                  {tutorial_business_plan.map((plan, index) => (
                    <Col
                      xl={3}
                      lg={3}
                      md={5}
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                      className="mb-4"
                    >
                      <PlanCard
                        key={index}
                        planName={plan.planName}
                        saving={plan.saving}
                        originalPrice={plan.originalPrice}
                        discount={plan.discount}
                        type="business"
                        handleClick={handleClick}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>

            {tutorial_data.map((item, index) => (
              <Col xl={12} lg={12} md={12} sm={12} xs={12} key={index}>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "4%" }}
                >
                  <SectionTitle title={item.title} />
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "32px",
                  }}
                >
                  <Row
                    style={{
                      flexDirection: index % 2 === 0 ? "row" : "row-reverse", // Alternate layout
                    }}
                  >
                    <Col
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      className="text-column"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {item.body.map((paragraph, pIndex) => (
                        <SectionBody
                          style={pIndex !== 0 ? { marginTop: "5%" } : {}}
                          key={pIndex}
                        >
                          {paragraph}
                        </SectionBody>
                      ))}
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={0} xs={0}></Col>
                    <Col
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      className="image-column"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <ReactPlayer
                        url={item.videoURL}
                        playing={false}
                        width="90%"
                        height="262px"
                        controls
                        light={true} // Optional: Adds a thumbnail
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            ))}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default TutorialPage;
