import React from "react";

/**
 * Parses a message string containing [links] and {{placeholders}}.
 * @param {string} message - The message string to parse.
 * @returns {Array} Parsed message parts.
 */
export const parseBannerMessage = (message) => {
  const parts = [];
  const regex = /\[([^\]]+)\]|\{\{(.*?)\}\}/g;
  let match;
  let lastIndex = 0;

  while ((match = regex.exec(message)) !== null) {
    if (lastIndex < match.index) {
      parts.push({ text: message.slice(lastIndex, match.index), type: "text" });
    }

    if (match[1]) {
      parts.push({ text: match[1], type: "link" });
    } else if (match[2]) {
      parts.push({ text: match[2], type: "placeholder" });
    }

    lastIndex = regex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push({ text: message.slice(lastIndex), type: "text" });
  }

  return parts;
};

/**
 * Renders parsed message parts into React components.
 * @param {Array} parts - Array of parsed message parts with text and type.
 * @param {Object} options - Options including styles and link click handler.
 * @returns {React.ReactNode[]} Rendered message components.
 */
export const renderMessage = (parts, options) => {
  return parts.map((part, index) => {
    switch (part.type) {
      case "link":
        return (
          <span
            key={index}
            style={options.styles.linkText}
            onClick={() => {
              if (options.onLinkClick) {
                options.onLinkClick(part.text);
              }
            }}
          >
            {part.text}
          </span>
        );
      case "placeholder":
        return (
          <span key={index} style={options.styles.placeholderText}>
            {part.text}
          </span>
        );
      default:
        return (
          <span key={index} style={options.styles.text}>
            {part.text}
          </span>
        );
    }
  });
};
