import React from 'react';
import { Helmet } from 'react-helmet';

const HeadMetaTags = () => {
  // Get the dynamic values from environment variables
  const hostUrl = process.env.REACT_APP_WEBSITE_URL || 'https://myforpets.com/'; // Default URL in case the environment variable is missing
  const imageUrl = `${hostUrl}/web-app-manifest-512x512.png`;  // Full URL to the image

  return (
    <Helmet>
      {/* Open Graph meta tags */}
      <meta property="og:title" content="Best Pet Products & Services" />
      <meta property="og:description" content="Find top-quality pet products and services. Subscribe for exclusive offers and discounts" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta property="og:url" content={hostUrl} />
      <meta property="og:type" content="website" />
      
      {/* Other meta tags */}
      <meta name="description" content="Explore the best pet care products and services. Subscribe for discounts!" />
      <meta name="robots" content="index, follow" />
      <link rel="icon" href={`${hostUrl}/favicon.ico`} />
      
    </Helmet>
  );
};

export default HeadMetaTags;
