import { Modal } from "react-bootstrap";
import "./cart-modal.style.css";
import {
  FETCH_CART,
  REMOVE_CART_ITEM,
  UPDATE_QUANTITY,
  VALIDATE_QUANTITY,
  VALIDATE_QUANTITY_LIMITATION,
} from "../../graphQL/cart/cart.query";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import CartList from "../cart-list/cart-list.component";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400 } from "../../utils/colors";
import CustomButton from "../button/button.component";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setUserCart, setUserCartCount } from "../../store/cart/cart.action";
import { selectCartCount } from "../../store/cart/cart.selector";
import { toast } from "react-toastify";
import GlobalModel from "../global-model/global-model.component";

const CartModal = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [showEmptyCart, setShowEmptyCart] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const cartCount = useSelector(selectCartCount);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState({
    title: "",
    message: "",
    buttonTitle: "",
  });

  const [quantityError, setQuantityError] = useState("");

  const [validateQuantity] = useMutation(VALIDATE_QUANTITY);
  const [validateQuantityLimitation] = useMutation(
    VALIDATE_QUANTITY_LIMITATION
  );

  const [updateItemQuantity] = useMutation(UPDATE_QUANTITY, {
    onCompleted: (success) => {
      if (success && success.updateQuantity) {
        let result = success.updateQuantity;
        if (result.success) {
          fetchCart();
        } else {
          setIsLoading(false);
          toast.error(result.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    },
  });

  const [removeCartItem] = useMutation(REMOVE_CART_ITEM, {
    onCompleted: (data) => {
      if (data && data.removeCartItem) {
        let result = data.removeCartItem;
        if (result.success) {
          fetchCart();
        } else {
          setIsLoading(false);
          toast.error(result.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    },
  });

  const handleUpdateQuantity = async (updatedQuantity) => {
    setIsLoading(true);
    let restrictionObject = {
      cartQuantity: parseInt(updatedQuantity.quantity),
      itemId: updatedQuantity.itemId,
      variantCombination: updatedQuantity.variantCombination,
      itemType: "Product",
    };
    let result = await validateQuantityLimitation({
      variables: { itemQuantityLimitation: [restrictionObject] },
    });
    if (result.data.validateQuantityLimitation.success) {
      console.log("Elese", result.data.validateQuantityLimitation);
      let itemObject = {
        quantity: updatedQuantity.quantity,
        itemId: updatedQuantity.itemId,
        variantCombination: updatedQuantity.variantCombination,
        type: "Product",
      };

      let response = await validateQuantity({
        variables: {
          itemQuantity: [itemObject],
        },
      });

      if (response.data.validateQuantity.success) {
        if (Object.keys(updatedQuantity).length !== 0) {
          updateItemQuantity({
            variables: {
              quantity: updatedQuantity.quantity,
              itemId: updatedQuantity.itemId,
              businessId: updatedQuantity.businessId,
              variantCombination: updatedQuantity.variantCombination,
            },
          });
        }
      } else {
        const itemAvaibility = response.data.validateQuantity.itemAvaibility;
        setIsLoading(false);
        setIsErrorVisible(true);
        setIsErrorMessage({
          title: itemAvaibility[0].errorTitle,
          message: itemAvaibility[0].message,
          buttonTitle: itemAvaibility[0].buttonTitle,
        });
        // toast.warn(
        //   `Quantity Error: ${result.data.validateQuantityLimitation.itemAvaibility[0].itemName} available quantity is: ${result.data.validateQuantityLimitation.itemAvaibility[0].availableQuantity}`,
        //   {
        //     position: "top-center",
        //     autoClose: 7000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   }
        // );
      }
    } else {
    //  console.log("Elese", result.data.validateQuantityLimitation);
      const itemAvaibility =
        result.data.validateQuantityLimitation.itemAvaibility;
      setIsLoading(false);
      setIsErrorVisible(true);
      setIsErrorMessage({
        title: itemAvaibility[0].errorTitle,
        message: itemAvaibility[0].message,
        buttonTitle: itemAvaibility[0].buttonTitle,
      });
      // toast.warn(
      //   `Quantity limitation Error: ${result.data.validateQuantityLimitation.itemAvaibility[0].itemName} available quantity is: ${result.data.validateQuantityLimitation.itemAvaibility[0].availableQuantity}`,
      //   {
      //     position: "top-center",
      //     autoClose: 7000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   }
      // );
    }
  };

  const handleDeleteCartItem = (cartItem) => {
    setIsLoading(true);
    removeCartItem({
      variables: {
        itemId: cartItem.itemId,
        businessId: cartItem.businessId,
        variantCombination: cartItem.variantCombination,
      },
    });
  };

  useEffect(() => {
    if (props.show) {
      fetchCart();
    }
  }, [props.show]);

  const [fetchCart] = useLazyQuery(FETCH_CART, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      // console.log(data.fetchCart, "Marketplace CART SCREEN");
      if (data && data.fetchCart) {
        if (data.fetchCart.items.length != 0) {
          setCartItems(data.fetchCart.items);
          setCart(data.fetchCart);
          dispatch(setUserCartCount(data.fetchCart.cartCount));
          dispatch(setUserCart(data.fetchCart.items));
          setShowEmptyCart(false);
        } else {
          dispatch(setUserCartCount(0));
          setShowEmptyCart(true);
        }
      } else {
        dispatch(setUserCartCount(0));
        setShowEmptyCart(true);
      }
      setIsLoading(false);
    },
  });

  const validateItemQuantity = async (cartItemInfo) => {
    const items = cartItemInfo.items;
    let itemArray = [];
    let itemLimitationArray = [];
    await items.forEach((item) => {
      let itemObject = {
        itemId: item._id,
        quantity: item.quantity,
        type: cartItemInfo.type,
        variantCombination: !item?.variantCombination
          ? null
          : item.variantCombination,
      };
      let itemRestrictionObject = {
        itemId: item._id,
        cartQuantity: parseInt(item.quantity),
        itemType: cartItemInfo.type,
        variantCombination: !item?.variantCombination
          ? null
          : item.variantCombination,
      };
      itemLimitationArray.push(itemRestrictionObject);
      itemArray.push(itemObject);
    });
    let result = await validateQuantityLimitation({
      variables: {
        itemQuantityLimitation: itemLimitationArray,
      },
    });
    if (result.data.validateQuantityLimitation.success) {
      let response = await validateQuantity({
        variables: {
          itemQuantity: itemArray,
        },
      });
      if (response.data.validateQuantity.success) {
        // console.log("Cart------------", cartItemInfo.businessId)
        setIsCheckoutLoading(false);
        props.onHide();
        navigation("/buy?type=product", { state: cartItemInfo.businessId });
      } else {
        const itemAvaibility = response.data.validateQuantity.itemAvaibility;
        // console.log("VAlue---------------", itemAvaibility);
        setIsLoading(false);
        setIsErrorVisible(true);
        setIsErrorMessage({
          title: itemAvaibility[0].errorTitle,
          message: itemAvaibility[0].message,
          buttonTitle: itemAvaibility[0].buttonTitle,
        });
      }
    } else {
      const itemAvaibility = result.data.validateQuantityLimitation.itemAvaibility;
      // console.log("VAlue---------------", itemAvaibility);
      setIsLoading(false);
      setIsErrorVisible(true);
      setIsErrorMessage({
        title: itemAvaibility[0].errorTitle,
        message: itemAvaibility[0].message,
        buttonTitle: itemAvaibility[0].buttonTitle,
      });
    }
  };

  const handleCheckout = async (cartItemInfo) => {
    // console.log("Cart------------", cartItemInfo)
    setIsCheckoutLoading(true);
    await validateItemQuantity(cartItemInfo);
    setIsCheckoutLoading(false);
  };

  const handleEmptyCartClick = () => {
    handleClose();
    navigation(`/products/category/Pets and Animals`);
  };
  const handleClose = () => {
    props.onHide();
    setCartItems([]);
    setCart([]);
  };
  return (
    <Modal
      {...props}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      fullscreen
      //   backdrop="static"
      className="cart-modal-style"
      //   style={{ display: 'flex', justifyContent: 'end' }}
    >
      {isErrorVisible && (
        <GlobalModel
          show={isErrorVisible}
          onHide={() => {
            setIsErrorVisible(false);
          }}
          onRemoveClick={() => {
            setIsErrorVisible(false);
          }}
          title={isErrorMessage.title}
          bodyMessage={isErrorMessage.message}
          buttonTitle={isErrorMessage.buttonTitle}
          buttonType="normal"
        />
      )}
      <div style={{ padding: "3%", height: "100vh", overflowY: "auto" }}>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {showEmptyCart && cartCount === 0 ? (
              <label>Cart</label>
            ) : (
              <label>Cart ({cartCount})</label>
            )}
          </Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <div
            style={{
              height: "86vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : showEmptyCart ? (
          <Modal.Body
            style={{
              marginBottom: "2%",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "1%",
            }}
          >
            <div
              style={{
                height: "65vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomLabel style={{ fontSize: "20px", fontWeight: "600" }}>
                The shopping cart is empty
              </CustomLabel>
              <CustomLabel style={{ color: netural400, fontWeight: "400" }}>
                You seem to have a good taste...
              </CustomLabel>
              <CustomButton
                title={"Start Shopping"}
                customStyle={{ width: "80%", fontWeight: "bold" }}
                handleButtonClick={handleEmptyCartClick}
              />
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body
            style={{
              marginBottom: "2%",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "1%",
            }}
          >
            {cartItems.map((cartItemInfo, index) => {
              return (
                <CartList
                  key={index}
                  cartItemInfo={cartItemInfo}
                  index={index}
                  isCheckoutLoading={isCheckoutLoading}
                  handleUpdateQuantity={handleUpdateQuantity}
                  handleDeleteCartItem={handleDeleteCartItem}
                  handleCheckout={handleCheckout}
                />
              );
            })}
          </Modal.Body>
        )}
      </div>
    </Modal>
  );
};

export default CartModal;
