import { IoCloseSharp } from "react-icons/io5";
import { netural50, neutral600 } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { parseBannerMessage, renderMessage } from "../../utils/parse-message";

const ReferralBanner = ({
  className = "",
  style = {},
  promotionMessage,
  isLoading,
}) => {
  const [visible, setVisible] = useState(true);
  const navigation = useNavigate();
  const parsedMessage = isLoading
    ? ""
    : parseBannerMessage(promotionMessage?.message);

  if (!visible) return null;

  const handleBannerTextClick = () => {};

  return (
    <div
      style={{
        justifyContent: "space-between",
        width: "100%",
        ...style,
      }}
      className={`${className}`}
    >
      {/* Center-aligned title */}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px", // Adds spacing between text and icon
            background: "linear-gradient(to bottom, #7D7D7D, #1B1C1E)",
            color: "#fff",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "8px",
            paddingRight: "8px",
            borderRadius: "16px",
          }}
        >
          <CustomLabel
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#fff",
            }}
          >
            {renderMessage(parsedMessage, {
              styles: {
                text: {
                  color: netural50,
                },
                placeholderText: {
                  color: neutral600,
                },
                linkText: {
                  textDecorationLine: "underline",
                },
              },
              onLinkClick: handleBannerTextClick,
            })}
          </CustomLabel>

          {/* Close Icon with spacing */}
          <IoCloseSharp
            onClick={() => setVisible(false)}
            color="#fff"
            size={20}
            style={{ marginLeft: "8px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralBanner;
